import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from "lib/utility/i18n";
import { constants } from "../constants";
import PaginatedContent from './PaginatedContent';
import { Button } from 'reactifi';

const Queue = (props) => {
  const {
    activePage,
    completedButtonLabel,
    completedItems,
    content,
    handleContentNav,
    handleTabChange,
    isLibrary,
    isSearching,
    setActivePage,
    showIcons,
    processStatus
  } = props;

  const paginatedContentProps = { activePage, handleContentNav, setActivePage, showIcons, processStatus };

  const getContent = useCallback(isCompleted => {
    return content.filter(contentItem => {
      const { user_content: { progress } = {} } = contentItem;
      return isCompleted ? progress?.status === constants.COMPLETED :
        progress?.status !== constants.COMPLETED;
    });
  });

  const onViewInProgress = useCallback(() => {
    handleTabChange(0);
  });

  const completedContent = getContent(true);
  const playlistContent = getContent(false);

  const getEmptyListMessage = () => {
    if (!isLibrary) return i18n.t('Hooray you finished all the modules in this playlist! Try a new playlist in Discover Additional Playlists below.');

    return isSearching ? i18n.t('We did not find related activities. You may want to update your search.') : i18n.t('You have completed all the activities. Check out the Completed tab to review.');
  }

  if (!completedItems) {
    return (
      playlistContent.length > 0 ?
        <PaginatedContent
          {...paginatedContentProps}
          collection={playlistContent}
        /> :
        <div className="empty-list">
          {getEmptyListMessage()}
        </div>
    );
  } else {
    return (
      completedContent.length > 0 ?
        <PaginatedContent
          {...paginatedContentProps}
          collection={completedContent}
        /> :
        <div className="empty-list">
          {i18n.t('Your completed activities appear here!')}
          <Button
            branded={true}
            className="btn-view-inprog capitalize"
            label={completedButtonLabel}
            onClick={onViewInProgress}
            style="primary"
          />
        </div>
    );
  }
}

Queue.propTypes = {
  activePage: PropTypes.number,
  completedButtonLabel: PropTypes.string,
  completedItems: PropTypes.bool,
  content: PropTypes.array.isRequired,
  handleContentNav: PropTypes.func,
  handleTabChange: PropTypes.func,
  isLibrary: PropTypes.bool,
  isSearching: PropTypes.bool,
  showIcons: PropTypes.bool,
  setActivePage: PropTypes.func,
  processStatus: PropTypes.string
};

Queue.defaultProps = {
  completedButtonLabel: i18n.t('View In Progress')
};

export default Queue;
