import React from 'react';
import createStore from './store/learnerDashboardStore';
import { RootProvider } from 'reactifi/dist/es/react-on-rails/RootProvider';
import LearnerDashboardContainer from './containers/LearnerDashboardContainer';

export default (props, _railsContext) => {
  const store = createStore(props || {});

  return (
    <RootProvider store={store}>
      <LearnerDashboardContainer
        {...props}
        contentServiceUrl={_railsContext.content_service_url}
        jwt={_railsContext.jwt}
      />
    </RootProvider>
  );
};
