import PropTypes from 'prop-types';
import React from 'react';
import PlaylistCard from '../../components/PlaylistCard';
import DiagnosticCard from '../../components/DiagnosticCard';
import { injectCustomerColorsCSS } from 'lib/utility/helpers';

export default class PlaylistsWidget extends React.Component {
  static propTypes = {
    program: PropTypes.object,
    playlistLimit: PropTypes.number
  };

  static defaultProps = {
    program: {
      playlists: [
        {
          name: "Life Transactions",
          description: "The basics...what you need to know about your finances",
          image: "http://s3.amazonaws.com/everfi-next/uploads/demo-images/Savings.png"
        },
        {
          name: "Financing Education",
          description: "The tools you need to prepare for education expenses.",
          image: "http://s3.amazonaws.com/everfi-next/uploads/demo-images/Credit+Scores.png"
        },
        {
          name: "Retirement Planning",
          description: "Plan now for your future retirement.",
          image: "http://s3.amazonaws.com/everfi-next/uploads/demo-images/Credit+Scores.png"
        },
        {
          name: "Aging Parents",
          description: "What you need to know to care for aging parents and/or sick children.",
          image: "http://s3.amazonaws.com/everfi-next/uploads/demo-images/Considering+Home+Ownership.png"
        },
        {
          name: "Financial Health",
          description: "The basics...what you need to know about your finances",
          image: "https://s3.amazonaws.com/everfi-next/uploads/demo-images/Insurance.png"
        }
      ]
    },
    playlistLimit: 25
  };

  playlistItems() {
    const { program } = this.props;
    return (
      program.playlists.slice(0, this.playlistLimit).map(playlist => {
        const genericIncentive = program.incentives.find(incentive => incentive.playlist_id === playlist.id);
        return (
          <PlaylistCard
            genericIncentive={genericIncentive}
            key={playlist.id}
            playlist={playlist}
            program={program}
            learnerDahsboard={false}
          />
        )
      })
    );
  }

  diagnosticItems() {
    const { program } = this.props;
    return (
      program.diagnostics.slice(0, this.playlistLimit).map(diagnostic => {
        const genericIncentive = program.incentives.find(incentive => incentive.diagnostic_id === diagnostic.id);
        return (
          <DiagnosticCard
            key={diagnostic.id}
            program={program}
            diagnostic={diagnostic}
            genericIncentive={genericIncentive}
            learnerDahsboard={false}
          />
        )
      })
    );
  }

  get playlistLimit() {
    if (this.props.playlistLimit > this.props.program.playlists.length) {
      return this.props.program.playlists.length;
    } else {
      return this.props.playlistLimit;
    }
  }

  render() {
    injectCustomerColorsCSS(this.props.program.primary_color, this.props.program.secondary_color);
    return (
      <div className="row other-playlists clearfix">
        <div className="col-12 col-centered">
          {this.playlistItems()}
          {this.diagnosticItems()}
        </div>
      </div>
    )
  }
}
