import { i18n } from 'reactifi/dist/es/i18n/translate';

export function injectCustomerColorsCSS(primary, secondary = '', contentOnly = false) {
  if(typeof document === 'undefined') return false;

  const exists = document.getElementById(`custom-colors-${primary}${secondary}${contentOnly}`);
  if (!primary || exists) {
    return;
  }

  let style = document.createElement('style');
  style.type = 'text/css';
  style.id = `custom-colors-${primary}${secondary}${contentOnly}`;

  const btnSet = `

    .guest-layout .btn-primary, .guest-layout .btn.btn-primary:hover, .guest-layout .btn.btn-primary:focus, .guest-layout .btn.btn-primary:active,
    .foundry .btn-primary, .foundry .btn.btn-primary:hover, .foundry .btn.btn-primary:focus, .foundry .btn.btn-primary:active,
    .dialog .btn-primary, .btn-primary, .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active {
      background-color: ${primary} !important;
      border-color: ${primary} !important;
    }

    .btn-secondary:not(.btn-sso), .btn-secondary:not(.btn-sso):hover, .btn-secondary:not(.btn-sso):focus, .btn-secondary:not(.btn-sso):active {
      border-color: ${primary} !important;
      color: ${primary} !important;
    }

    .guest-layout .btn-link, .guest-layout .btn-link:hover, .guest-layout .btn-link:focus, .guest-layout .btn-link:active,
    .foundry .btn-link, .foundry .btn-link:hover, .foundry .btn-link:focus, .foundry .btn-link:active  {
      color: ${primary} !important;
    }

    .playlist-top-border {
      border-top: 3px solid ${primary} !important;
    }

    .evaluation.foundry .btn {
      background: white;
    }

    .playlists .item h3.title {
      color: ${primary} !important;
    }

    .playlist-card .incentive .incentive-message {
      color: $colors-grey-800;
    }
    .playlist-card .incentive .material-icon {
      color: ${primary} !important;
    }

    .playlist-header .incentive-learner .incentive header .icon-category-value button {
      color: ${primary} !important;
    }

    .playlist-header .incentive-learner .incentive header .icon-category-value svg {
      color: ${primary} !important;
    }

    .playlist-header .incentive-learner .incentive header .icon-category-value svg:hover {
      color: ${primary} !important;
    }

    .playlist-header .incentive-learner .incentive .incentive-text {
      color: ${primary} !important;
    }

    .playlist-header .incentive-learner .incentive .incentive-text:hover {
      color: ${primary} !important;
    }

    .foundry .navbar-right li a,
    .navbar-right .header-menu .btn-secondary,
    .navbar-right .header-menu i.zmdi,
    .navbar-right .header-menu svg.fa-icon,
    .navbar-right .header-menu .user-menu li a {
      color: ${primary} !important;
    }

    a.branded-link, .branded-link {
      color: ${primary} !important;
    }

    .update-answers a {
      color: ${primary} !important;
    }

    .help-mail-to a {
      color: ${primary} !important;
      //font-size: 14px;
    }

    .foundry.dashboard .border-branded, .foundry.personalized_dashboard .border-branded,
    .foundry.true_learner_dashboard .border-branded {
      border-color: ${primary} !important;
    }

    .foundry .nav.nav-tabs.branded a.active {
      color: ${primary};
    }

    .foundry .nav.nav-tabs.branded a.active {
      color: ${primary} !important;
    }

    .foundry.dashboard .nav.nav-tabs.branded:not([data-tab-color]) > a:after {
      border-color: ${primary} !important;
    }

    .foundry.dashboard .nav.nav-tabs.branded:not([data-tab-color]) > a:after {
      border-color: ${primary};
    }
   
  `;

  const contentPage = `

    .interactive-notification .btn-primary, .interactive-notification .btn.btn-primary:hover,
    .interactive-notification .btn.btn-primary:focus,
    .interactive-notification .btn.btn-primary:active,
    .course-content #help-modal .btn-primary, .course-content #help-modal .btn.btn-primary:hover,
    .course-content #help-modal .btn.btn-primary:focus,
    .course-content #help-modal .btn.btn-primary:active,
    .course-content .modal-dialog .btn-primary, .course-content .modal-dialog .btn.btn-primary:hover,
    .course-content .modal-dialog .btn.btn-primary:focus,
    .course-content .modal-dialog .btn.btn-primary:active {
      background-color: ${primary} !important;
      border-color: ${primary} !important;
    }

    .course-content #help-modal button.btn-secondary:enabled,
    #language-unavailable .modal-footer button.btn-secondary {
      color: ${primary} !important;
      border-color: ${primary} !important;
    }

    .foundry .modal.languages-modal .language-picker-area .language-option.is-selected {
      color: ${primary} !important;
      .fa-icon {
        color: ${primary} !important;
      }
    }

    .foundry #help-modal button.btn-secondary:enabled {
      color: ${primary} !important;
      .fa-icon {
        color: ${primary} !important;
      }
    }

    .course-content .border-branded {
      border-color: ${primary} !important;
    }

    .diagnostic .evaluation.foundry .evaluation-header,
    .assessment .evaluation.foundry .evaluation-header {
      background-color: ${primary} !important;
      max-width: 800px;
    }

    .diagnostic .evaluation.foundry .section.intro-section .intro-title,
    .assessment .evaluation.foundry .section.intro-section .intro-title {
      color: ${primary};
    }

    .diagnostic .evaluation.foundry .clear-selection .btn-link,
    .assessment .evaluation.foundry .clear-selection .btn-link,
    .course-content .navbar-right li a {
      color: ${primary};
    }

    .diagnostic .evaluation.foundry .section-container .section .section-title,
    .assessment .evaluation.foundry .section-container .section .section-title {
      background-color: ${primary} !important;
      border-bottom: 2px solid ${primary} !important;
    }

    .diagnostic .evaluation.foundry .btn-toolbar,
    .assessment .evaluation.foundry .btn-toolbar {
      border-color: ${primary} !important;
    }

    .foundry .btn-primary, .foundry .btn.btn-primary:hover,
    .foundry .btn.btn-primary:focus, .foundry .btn.btn-primary:active {
      background-color: ${primary} !important;
      border-color: ${primary} !important;
    }

    .foundry .btn-secondary:not(.btn-sso), .foundry .btn-secondary:not(.btn-sso):hover,
    .foundry .btn-secondary:not(.btn-sso):focus, .foundry .btn-secondary:not(.btn-sso):active {
      border-color: ${primary} !important;
      color: ${primary} !important;
    }

    .foundry .checkbox input:checked + .input-helper:before,
    .foundry .radio input:checked + .input-helper:before { border-color: ${primary}; }

    .foundry .checkbox input:checked + .input-helper:before { color: ${primary}; background-color: ${primary}; }

    .foundry .radio .input-helper:after { background: ${primary}; }

    .foundry .pagination-wrapper .pagination.pagination-branded li.active:not(.arrow-button) button {
      border-color: ${primary} !important;
      background-color: ${primary} !important;
    }
    .foundry .pagination-wrapper .pagination.pagination-branded li:not(.active) button {
      color: ${primary} !important;
    }
    .foundry .pagination-wrapper .pagination.pagination-branded li:not(.active):not(.arrow-button) button {
      border-color: ${primary} !important;
    }

    .foundry #help-modal .modal-body .help-mail-to a {
      color: ${primary};
    }

    .foundry .nav.nav-tabs:not([data-tab-color]) button.nav-link:after {
      border-bottom: 4px solid ${primary};
    }

    .foundry a.branded-link {
      color: ${primary};
    }
  `;
  const colors = `
    .plc-path{ stroke: ${primary} !important; }
    a { color: ${primary} }
    .primary { color: ${primary} !important }
  `;

  if (!contentOnly) { // using ! for backward compat
    style.innerHTML = btnSet + contentPage + colors;
  } else {
    style.innerHTML = contentPage + colors;
  }
  document.head.appendChild(style);
}

export function getCustomizedText(text_customization, key) {
  const defaultStrings = {
    additional_playlists_text: i18n.t('Discover Additional Playlists'),
    activities_text: i18n.t('activities'),
    playlists_text: i18n.t('Playlists')
  }
  if (text_customization && text_customization[key]) {
    return text_customization[key];
  } else {
    return defaultStrings[key]
  }
}

export function createNameOrGuest(data) {
  if(data.guest) {
    return i18n.t('Guest User');
  } else {
    const { first_name, last_name } = data;
    return first_name && last_name ? `${data.first_name} ${data.last_name}` : '';
  }
}
