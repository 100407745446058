import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Rails from '@rails/ujs';
import { Button, SSOButton } from 'reactifi';
import i18n from 'lib/utility/i18n';
import { injectCustomerColorsCSS } from 'lib/utility/helpers';
import googleLogoImg from '../../../../images/google_logo.png';
import facebookLogoImg from '../../../../images/facebook_logo.png';

const LoginBox = ({ authCaps, authUrls, loginText, program, programId }) => {
  const [samlIdProviders, setSamlIdProviders] = useState([]);

  useEffect(() => {
    Rails.refreshCSRFTokens();
    if (program) {
      injectCustomerColorsCSS(program.primary_color || null, program.secondary_color || null);
    }
    loadSamlIdProviders();
  }, []);

  const loadSamlIdProviders = async () => {
    const idProviders = await fetch(`/api/data/programs/${programId}/saml_urls.json`).then(res => res.json());

    setSamlIdProviders(idProviders);
  }

  const constructUrl = authType => {
    return authUrls ? (authUrls.find(entry => entry.auth_type === authType) || authUrls[0]).url : '';
  }

  const socialLogin = () => {
    if (!authCaps.social) return null;

    return (
      <>
        <SSOButton branded={true} href={constructUrl('google')} label={i18n.t('Continue with Google')} imgUrl={googleLogoImg} data={{ action: 'login-with-google' }} />
        <SSOButton branded={true} href={constructUrl('facebook')} label={i18n.t('Continue with Facebook')} imgUrl={facebookLogoImg} icon="facebook" iconFamily="fa" data={{ action: 'login-with-facebook' }} />
      </>
    );
  }

  const emailLogin = () => {
    if (!authCaps.email) return null;

    return (
      <Button
        href={constructUrl('email_login')}
        style="primary"
        label={i18n.t('Continue with Email or Username')}
        branded={true}
        block={true}
        className="large"
        data={{ action: 'login-with-email-or-username' }}
      />
    );
  }

  const usernameLogin = () => {
    if (!authCaps.username) return null;

    return (
      <Button
        href={constructUrl('username')}
        style="primary"
        label={i18n.t('Continue with Username')}
        branded={true}
        block={true}
        className="large"
        data={{ action: 'login-with-username' }}
      />
    );
  }

  const canShowSaml = () => authCaps.saml && samlIdProviders.length > 0;

  const samlLogin = () => {
    if (!canShowSaml()) return null;

    return samlIdProviders.map(provider => {
      const label = i18n.t('Log In with %s Account', { postProcess: 'sprintf', sprintf: [provider.display_name] });
      const authUrl = provider.auth_url.replace('relay_state_value', encodeURIComponent(location.href));

      return <SSOButton
        data={{ action: 'login-with-sso' }}
        branded={true} key={provider.id}
        href={authUrl}
        label={label}
        imgUrl={program?.social_fav_icon_url} />;
    });
  }

  const conditionalOrTag = () => {
    // only display 'or' heading tag when one of the default auth capabilites are active if saml cap active
    if (!authCaps.email || (!authCaps.social && !canShowSaml())) return null;

    return (
      <div className="conditional-text">
        <span>{i18n.t('or')}</span>
      </div>
    );
  }

  const showSsoLogin = authCaps.social || canShowSaml();

  return (
    <>
      <div className="login-title">
        <h2 tabIndex="-1">{i18n.t('Log In')}</h2>
        <p className="p2 text-muted">{loginText}</p>
      </div>

      {showSsoLogin && (
        <div className="sso-login">
          {socialLogin()}
          {samlLogin()}
        </div>
      )}

      {conditionalOrTag()}
      {emailLogin()}
      {usernameLogin()}

      {(authCaps.email || authCaps.username) && (
        <div className="register-text">
          {i18n.t("Don't have an account?")}
          <a className="branded-link m-l-5" href={constructUrl('email_registration')}>{i18n.t('Register')}</a>
        </div>
      )}
    </>
  );
}

LoginBox.defaultProps = {
  loginText: i18n.t('Login to save your progress')
};

LoginBox.propTypes = {
  authCaps: PropTypes.object.isRequired,
  authUrls: PropTypes.array.isRequired,
  loginText: PropTypes.string,
  program: PropTypes.object,
  programId: PropTypes.number
};

export default LoginBox;
