import { readLocal, selectEntity, readEntityById } from 'reactifi/dist/es/actions/coreApiActions';
import { readEntity } from 'reactifi/dist/es/actions/apiActions';
import { loadApiData } from 'reactifi/dist/es/utils/ApiUtil';

export function loadUserContent(programId, userId) {
  return async (dispatch, getState) => {
    await dispatch(readEntity('user_contents', 'external_access_token', { program_id: programId, user_id: userId }, null, { number: 1, size: 200 }));

    const { api: { user_content } } = getState();
    const progressIds = user_content && [...new Set(user_content.data.map(uc => uc.attributes.progress_id).filter(id => id))];

    if (progressIds?.length) {
      const filterParams = progressIds.map(id => `filter[id][]=${id}`).join('&');
      const progressUrl = `/api/data/progress.json?${filterParams}`;
      const progressData = await loadApiData(progressUrl, false);

      dispatch(readLocal(progressData));
    }
  }
}

export function loadPlaylistItems(programId, searchValue) {
  const query = searchValue && {
    name: {
      term: searchValue,
      fields: {
        content_name: 'word_start',
        content_slug: 'word_start',
        content_description: 'word_start'
      }
    }
  };
  return readEntity('playlist_items', 'content', { program_id: programId }, null, { number: 1, size: 1000 }, query);
}

export function selectProgram(programId) {
  return selectEntity(programId, 'programs', ['account', 'text_customization']);
}

export function loadPlaylists(program_id) {
  return readEntity('program_playlists', 'playlist', { program_id });
}

export function loadCustomization(id) {
  return readEntityById(id, 'customizations');
}

export function loadGateways(programId, promptCounts) {
  const queryParams = { program_id: programId, ...promptCounts };
  const gatewaysUrl = `/api/data/gateways?${Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')}`;

  return async dispatch => {
    const data = await loadApiData(gatewaysUrl, false);
    dispatch(readLocal(data));
  };
}

export function loadIncentives(programId) {
  return readEntity('incentives', '', { programId });
}

export function loadIncentiveActivities(programId, userId) {
  return readEntity('incentive_activities', 'incentive', { programId, userId });
}
