import PropTypes from 'prop-types';
import React from 'react';
import ItemIcon from 'lib/components/ItemIcon';
import i18n from 'lib/utility/i18n';
import { FAIcon } from 'reactifi/dist/es/components/fa-icon';
import { getLocaleVersion, isIncentiveActive } from '../common/functions';

export default class SharedCard extends React.Component {
  static propTypes = {
    cardType: PropTypes.string.isRequired,
    destinationUrl: PropTypes.string.isRequired,
    genericIncentive: PropTypes.object,
    incentive: PropTypes.shape({
      archived: PropTypes.bool,
      claim_message: PropTypes.string,
      incentive_activity: PropTypes.shape({
        status: PropTypes.string
      }),
      incentive_message: PropTypes.string
    }),
    program: PropTypes.shape({
      remove_content_images: PropTypes.bool,
      slug: PropTypes.string.isRequired
    }),
    iconUrl: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    learnerDashboard: PropTypes.bool,
    name: PropTypes.string.isRequired
  }

  incentiveMessage = () => {
    const { genericIncentive, incentive } = this.props;

    if (isIncentiveActive(incentive)) {
      const { claim_message, incentive_activity = {}, incentive_message } = incentive;
      const incentiveComplete = incentive_activity.status === 'completed';

      return (
        <div className="incentive-container">
          <FAIcon icon="star" style="solid" />
          <p className="incentive-message p2">{incentiveComplete ? claim_message : incentive_message}</p>
        </div>
      );
    } else if (genericIncentive && !genericIncentive.archived) {
      return (
        <div className="incentive-container">
          <FAIcon icon="star" style="solid" />
          <p className="incentive-message p2 fw-bold">{i18n.t('Incentive Available')}</p>
        </div>
      );
    }
  }

  render() {
    const {
      cardType,
      destinationUrl,
      id,
      iconUrl,
      learnerDashboard,
      name,
      program
    } = this.props;

    return (
      <div
        className={`col-12 col-lg-6 playlist-card playlist-top-border ${!learnerDashboard ? 'foundry dark-card-bg' : ''}`}
        data-object-type="playlists"
        data-object-id={id}
      >
        <div>
          <a href={destinationUrl} data-no-turbolink="true" role="button">
            <div className="playlist-card-top">
              <div className="left-content">
                {learnerDashboard ? <h3>{getLocaleVersion(this.props, 'name')}</h3> : <h2> {getLocaleVersion(this.props, 'name')} </h2>}
                <p className="card-description p2">{getLocaleVersion(this.props, 'tagline')}</p>
              </div>
              {!program.remove_content_images &&
                <div className="d-none d-md-block col-sm-3 icon-holder">
                  <ItemIcon
                    id={`${cardType}-${id}`}
                    stroke={true}
                    icon_url={iconUrl}
                    fill="transparent"
                  />
                </div>
              }
            </div>
            <div className="explore">
              <div className="incentive">
                <aside className="primary">
                  <div>
                    {this.incentiveMessage()}
                  </div>
                </aside>
              </div>
              <button
                type="button"
                id={`landing_page-playlist_button-${name}-${id}`}
                className="btn btn-secondary btn-secondary-branded btn-tertiary btn-tertiary-branded capitalize medium" //FC-533 leaving btn-tertiary to support legacy layouts
                aria-label="Explore Playlist"
              >
                {i18n.t('Explore')}
              </button>
            </div>
          </a>
        </div >
      </div >
    )
  }
}
