import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'reactifi/dist/es/i18n/translate';
import { getData } from 'reactifi/dist/es/json-api/JsonApiUtils';
import { DisplayWidthForCards } from 'reactifi/dist/es/components/Layout/Display/DisplayWidthForCards';
import { DisplayMaxWidthWide } from 'reactifi/dist/es/components/Layout/Display/DisplayMaxWidthWide';
import { CardsModule } from 'reactifi/dist/es/components/Layout/Modules/CardsModule';
import { CardsList } from 'reactifi/dist/es/components/Layout/Modules/components/CardsList';
import { PresentationCard } from 'reactifi/dist/es/components/presentation-card/component';
import { sanitizeUrl } from '@braintree/sanitize-url';

const PAGE_SIZE = 3;

const IncentivesWidget = ({ orientation = 'horizontal', program }) => {
  const [incentives, setIncentives] = useState([]);
  const [incentiveActivities, setIncentiveActivities] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const isVertical = orientation === 'vertical';

  const loadIncentives = async () => {
    const { data } = await getData(`/api/data/incentives/incentive_recommendations?incentive_count=${PAGE_SIZE}`, {
      filters: { program_id: program.id, exclude_nil_playlist: true },
      includes: ['playlist']
    });

    setIncentives(data);
  }

  const loadIncentiveActivities = async () => {
    const { data } = await getData(`/api/data/incentive_activities.json`);

    setIncentiveActivities(data);
  }

  useEffect(() => {
    loadIncentives();
    loadIncentiveActivities();
  }, []);

  const renderWidgetHeader = () => (
    <>
      <h2 className="title">{i18n.t('Incentives For You')}</h2>
      <p className="description m-b-30">{i18n.t('View playlists with incentives attached')}</p>
    </>
  )

  const getIncentiveCardBody = (id, message, completed_message) => {
    const currentActivity = incentiveActivities.find(activity => activity.incentive_id === parseInt(id));
    const modulesCompleted = currentActivity?.modules_completed;
    const totalModules = currentActivity?.modules_total;
    const msg = currentActivity?.status === 'completed' ? completed_message : message;

    return (
      <>
        <p>{msg}</p>
        <p className="m-b-0">
          <strong>{i18n.t('Modules Completed')}: </strong>
          {modulesCompleted ? modulesCompleted : '0'}{totalModules ? `/${totalModules}` : ''}
        </p>
      </>
    );
  }

  const renderIncentiveCard = ({ id, incentive_message, incentive_complete_text, playlist }) => {
    const card = (
      <PresentationCard
        key={id}
        type="narrow"
        title={playlist?.name}
        description={getIncentiveCardBody(id, incentive_message, incentive_complete_text)}
        data-object-type="incentive"
        data-object-id={id}
        buttonConfig={[{
          label: i18n.t('View Playlist'),
          style: 'secondary',
          rightIcon: 'arrow-right',
          href: sanitizeUrl(`/student/dashboard/${program.slug}/${playlist?.slug}${window.location.search}`),
          className: 'small',
          'data-action': 'view-playlist'
        }]}
      />
    );

    return isVertical ? <DisplayMaxWidthWide className="vertical-display">{card}</DisplayMaxWidthWide> : card;
  }

  const renderContent = () => {
    if (isVertical) {
      return (
        <>
          {renderWidgetHeader()}
          <CardsList>
            {incentives.map(renderIncentiveCard)}
          </CardsList>
        </>
      );
    }

    return (<>
      { !!incentives?.length && <DisplayWidthForCards>
        {renderWidgetHeader()}
        <CardsModule
          collection={incentives.slice((activePage * PAGE_SIZE) - PAGE_SIZE, activePage * PAGE_SIZE)}
          collectionMeta={{ total_count: incentives.length }}
          pageSize={PAGE_SIZE}
          onPageChange={setActivePage}
          isBranded={true}
          type="narrow"
          resultsDisplayed={false}
          showPageSizeDropdown={false}
          resetScroll={false}
          listClasses="p-b-0 m-b-0"
          wrapperClasses="m-b-30"
        >
          {incentive => renderIncentiveCard(incentive)}
        </CardsModule>
      </DisplayWidthForCards> }
    </>);
  }

  return (
    <div className={`foundry incentives-widget ${orientation}`}>
      {renderContent()}
    </div>
  );
}

IncentivesWidget.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  program: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    slug: PropTypes.string
  })
};

export default IncentivesWidget;
