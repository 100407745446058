import PropTypes from 'prop-types';
import React from 'react';

export default class ContentLegalCustomizations extends React.Component {
  static propTypes = {
    program_legal_customizations: PropTypes.array.isRequired,
    content_id: PropTypes.string.isRequired
  };

  render() {
    return (
      <div className="legal-customizations">
        {this.props.program_legal_customizations.filter((plc) => plc.content_id === this.props.content_id).map(
          (cust) => <div className="legal" dangerouslySetInnerHTML={{ __html: cust.custom_content }} />
        )}
      </div>
    )
  }
}
