import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'lib/utility/i18n';
import AuthTokenHelper from 'lib/utility/auth_token_helper';
import FoundryConfigs from 'lib/utility/foundry_configs';
import Survey from './survey.js';
import { pollUrl } from 'reactifi/dist/es/actions/downloadActions'
import { ProgressSpinner } from 'reactifi/dist/es/components/progress-spinner'

const poll = {
  INTERVAL: 1000, // every 1 sec
  TIMEOUT: 5000 // after 5 sec
};

export default class Diagnostic extends React.Component {
  static propTypes = {
    diagnostic: PropTypes.object,
    setDiagnosticStatus: PropTypes.func.isRequired,
    containerSelector: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { preparing: false };
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  }

  componentDidMount() {
    this.loadDiagnosticResponseSet();
  }

  endSurvey = () => {
    this.props.setDiagnosticStatus('failed')
  }

  requestOpts = () => {
    let params = `authenticity_token=${encodeURIComponent(AuthTokenHelper.getAuthToken)}&evaluation_slug=${this.props.diagnostic.evaluation_slug}`
    if (this.state.response_set) {
      params += `&response_set_id=${this.state.response_set.id}`
    }
    return {
      method: 'POST',
      headers: new Headers({ "Content-Type": "application/x-www-form-urlencoded" }),
      credentials: 'same-origin',
      body: params
    };
  }

  fetchOrThrowError = async (url, opts) => {
    let response = await fetch(url, opts);
    let json = await response.json();
    if (response.ok) {
      return json;
    } else {
      this.endSurvey();
      throw Error(json.error);
    }
  }

  loadDiagnosticResponseSet = async () => {
    let result = await this.fetchOrThrowError('/student/diagnostics', this.requestOpts());
    this.setState({ response_set: result.response_set });
  }

  fetchClassification = async () => {
    this.setState({ preparing: true });

    return await this.fetchOrThrowError('/student/diagnostics/classify', this.requestOpts());
  }

  fetchRecommendation = async (classification) => {
    let opts = this.requestOpts();
    opts.body += `&metadata=${encodeURIComponent(JSON.stringify(classification.metadata))}`;
    let result = await this.fetchOrThrowError('/student/diagnostics/recommend', opts);

    if (result.recommendation && result.recommendation.length > 0) {
      location.reload();
    } else {
      this.endSurvey();
    }
  }

  handleFinished = async () => {
    pollUrl({
      interval: poll.INTERVAL,
      max: poll.TIMEOUT,
      getData: async () => await this.fetchClassification(),
      checkComplete: data => data.metadata,
      onFinish: async data => await this.fetchRecommendation(data),
      onTimeout: this.endSurvey
    })
  }

  render() {
    let survey_ready = this.state.response_set;

    if (this.isIE11) {
      // clone backdrop to prevent ie11 focus issue
      $('.diagnostic').prev().replaceWith(
        `<div>
          <div class="modal-backdrop fade in"></div>
        </div>`
      );
    }

    if (this.state.preparing) {
      return (
        <div className="step-info">
          <ProgressSpinner classNames={"preloader pl-xl pls-primary"} />
          <h1 className="preparing">{i18n.t('Preparing your summary')}</h1>
        </div>
      );
    } else if (survey_ready) {
      return (
        <Survey
          containerSelector={this.props.containerSelector}
          evalufiConfig={FoundryConfigs.evalufi}
          language={i18n.language}
          onFinished={this.handleFinished}
          responseSet={this.state.response_set}
        />
      );
    } else {
      return <ProgressSpinner classNames={"preloader pl-xl pls-primary"} />;
    }
  }
}
