import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserUpdate } from 'reactifi/dist/es/browserUpdate';
import { ProgressSpinner } from 'reactifi/dist/es/components/progress-spinner';
import { Section } from 'reactifi/dist/es/components/Layout/Section';
import { injectCustomerColorsCSS } from 'lib/utility/helpers';
import * as tureLearnerDashboardActions from '../actions/trueLearnerDashboardActions';
import mapStateToProps from '../store/trueLearnerDashboardMapper';
import DashboardCustomization from '../components/DashboardCustomization';
import ProgramLearnerDashboardCustomization from '../components/ProgramLearnerDashboardCustomization';
import { FROM_LIBRARY_QUERY_STRING } from '../../../common/constants';
import isEqual from 'lodash/isEqual';
import { i18n } from 'reactifi/dist/es/i18n';
import { DisplayWidthForCards } from 'reactifi/dist/es/components/Layout/Display/DisplayWidthForCards';
import { PresentationCard } from 'reactifi/dist/es/components/presentation-card';
import { CardsModule } from 'reactifi/dist/es/components/Layout/Modules/CardsModule';
import RecommendationWidgetWrapper from '../../../components/landing_page/RecommendationWidgetWrapper';
import IncentivesWidgetWrapper from '../../../components/landing_page/IncentivesWidgetWrapper';
import { sanitizeUrl } from '@braintree/sanitize-url';

class TrueLearnerDashboardContainer extends React.Component {
  static propTypes = {
    dashboardCustomization: PropTypes.shape({
      enabled: PropTypes.bool,
      text: PropTypes.string
    }),
    dashboardCustomizationId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    program: PropTypes.shape({
      auth_capabilities: PropTypes.object,
      diagnostics: PropTypes.array,
      id: PropTypes.string,
      playlists: PropTypes.array,
      diagnostic: PropTypes.object,
      primary_color: PropTypes.string,
      remove_content_images: PropTypes.bool,
      secondary_color: PropTypes.string,
      slug: PropTypes.string
    }),
    programDashboardCustomization: PropTypes.string,
    programId: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {};
    this.actions = bindActionCreators({ ...tureLearnerDashboardActions }, this.props.dispatch);
  }

  async componentDidMount() {
    BrowserUpdate();

    const { dashboardCustomizationId, programId } = this.props;

    await this.actions.selectProgram(programId);
    this.actions.loadPlaylists(programId);
    this.actions.loadDiagnostics(programId);

    this.checkAndRedirectToLibrary(this.props.program?.slug);

    if (dashboardCustomizationId) {
      this.actions.loadCustomization(dashboardCustomizationId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.program && !isEqual(this.props.program, prevProps.program)) {
      injectCustomerColorsCSS(this.props.program.primary_color, this.props.program.secondary_color);
    }
  }

  checkAndRedirectToLibrary = programSlug => {
    if (!programSlug) return;

    const urlParams = new URLSearchParams(window.location.search);
    const shouldRedirectToLibrary = Boolean(urlParams.get(FROM_LIBRARY_QUERY_STRING));

    if (shouldRedirectToLibrary) {
      urlParams.delete(FROM_LIBRARY_QUERY_STRING);
      window.location = sanitizeUrl(`/student/dashboard/${programSlug}/content_library?${urlParams.toString()}`);
    }
  }

  renderWelcomeMessageBar = program => (
    <div className="welcome-message-bar" style={{ backgroundColor: program.primary_color }}>
      <div className="inner-container p-l-15 p-r-15">
        <h2 className="m-t-30 m-b-30">{i18n.t('Welcome!')}</h2>
      </div>
    </div>
  );

  render() {
    const { dashboardCustomization, program, programDashboardCustomization } = this.props;

    if (!program) return <ProgressSpinner />;

    return (<>
      {this.renderWelcomeMessageBar(program)}
      {program.playlists && (
        <Section id="featured-playlists" title={i18n.t('Featured Playlists')} type="cards" noBorder={true} className="p-t-10">
          <DisplayWidthForCards threeCardMax={true}>
            <h2>{i18n.t('Featured Playlists')}</h2>
            <CardsModule
              resultsDisplayed={false}
              showPageSizeDropdown={false}
              collection={program.playlists}
              corner="small"
              shadow="light"
              type="narrow"
            >
              {playlist => (
                <PresentationCard
                  type="narrow"
                  buttonConfig={[{
                    label: i18n.t('View Playlist'),
                    style: 'primary',
                    rightIcon: 'arrow-right',
                    href: sanitizeUrl(`/student/dashboard/${program.slug}/${playlist.slug}${window.location.search}`),
                    className: 'small',
                    'data-action': 'view-playlist'
                  }]}
                  buttonPosition="bottom"
                  mediaSize="small"
                  mediaUrl={playlist.image_url}
                  secondaryDetail={i18n.t(`${playlist.content_count} Modules`)}
                  title={playlist.name}
                  description={playlist.tagline}
                  data-object-type="playlist"
                  data-object-id={playlist.id}
                  data-action="view-playlist"
                />
              )}
            </CardsModule>

            {program.diagnostic && (
              <PresentationCard
                mediaSize="small"
                title={program.diagnostic?.name}
                description={program.diagnostic?.tagline}
                mediaUrl={program.diagnostic?.image_url}
                buttonConfig={[{
                  style: 'primary',
                  label: i18n.t('Start'),
                  rightIcon: 'arrow-right',
                  action: () => {
                    window.location.href = sanitizeUrl(`/student/personalized_dashboard/${program.slug}/${program.diagnostic.slug}${window.location.search}`);
                  }
                }]}
              />
            )}
          </DisplayWidthForCards>
        </Section>
      )}

      <Section id="recommended-modules" title={i18n.t('Recommended Modules')} type="cards" noBorder={true} className="p-t-10">
        <RecommendationWidgetWrapper program={program}/>
      </Section>

      <Section id="incentives-modules" title={i18n.t('Incentives For You')} type="cards" noBorder={true} className="p-t-10">
        <IncentivesWidgetWrapper program={program}/>
      </Section>

      {!!programDashboardCustomization && <ProgramLearnerDashboardCustomization customization={programDashboardCustomization} program={program} />}

      {!!dashboardCustomization && (
        <Section className="account-custom-dashboard" type="fullwidth" noPadding={true}>
          <DashboardCustomization customization={dashboardCustomization} program={program} />
        </Section>
      )}
    </>)
  }
}

export default connect(mapStateToProps)(TrueLearnerDashboardContainer);
