import PropTypes from 'prop-types';
import React from 'react';
import HelpModal from './help_modal';
import i18n from "lib/utility/i18n";
import { FAIcon } from 'reactifi/dist/es/components/fa-icon';
import LanguageUnavailableModal from '../apps/LearnerDashboard/components/LanguageUnavailableModal';
import { normalizeLocaleString } from '../common/functions';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default class MenuDropdown extends React.Component {
  static propTypes = {
    content: PropTypes.shape({
      supported_locales: PropTypes.array
    }),
    customText: PropTypes.string,
    customTextEnabled: PropTypes.bool,
    locales: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      current: PropTypes.bool,
      customText: PropTypes.string
    }))
  };

  static defaultProps = {
    modalClass: '',
    showMenu: false
  };

  constructor(props) {
    super(props);

    this.state = {
      showLanguageModal: false
    };
  }

  componentDidMount() {
    $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
      $(this).next('.dropdown-menu').toggleClass('show');
      $(this).attr('aria-expanded', (_, attr) => attr === 'true' ? 'false' : 'true');
      return false;
    });
  }

  handleMenuKeyDown = event => {
    this.menuKeyDown(
      event,
      '#navbar.dropdown .dropdown-menu:not(.language-menu) .dropdown-item [tabindex="-1"]',
      '#navbar.dropdown',
      '#navbar #menu-dropdown',
      '#navbar #menu-dropdown'
    );
  }

  handleSubMenuKeyDown = event => {
    this.menuKeyDown(
      event,
      '.language-menu:first-of-type [tabindex="-1"]',
      '#navbar .language-item',
      '#navbar .accessibility-link a',
      '#navbar .close-link a'
    );
  }

  menuKeyDown = (event, itemsSelector, menuSelector, prevSelector, postSelector) => {
    const menuItems = Array.from(document.querySelectorAll(itemsSelector));
    const currentIndex = menuItems.indexOf(event.target);

    switch (event.keyCode) {
    case 13:
    case 32:
      $(menuSelector).addClass('open');
      break;
    case 40:
      if (currentIndex === menuItems.length - 1) {
        $(menuSelector).removeClass('open');
        $(postSelector).focus();
      } else {
        $(menuSelector).addClass('open');
        $(menuItems[currentIndex + 1]).focus();
      }
      break;
    case 38:
      if (currentIndex <= 0) {
        $(menuSelector).removeClass('open');
        $(postSelector).focus();
      } else {
        $(menuSelector).addClass('open');
        $(menuItems[currentIndex - 1]).focus();
      }
      break;
    }
  }

  handleHomeKeyUp = event => {
    if (event.keyCode === 38) {
      $('#navbar .language-item a').focus();
    }
  }

  languageRedirect = locale => {
    const redirectUrl = locale.current ? window.location.href : locale.url;

    window.location.href = sanitizeUrl(redirectUrl);
  }

  checkLocales = (locale, event) => {
    event.preventDefault();

    const { url } = locale;
    const { content } = this.props;
    const supportedLocales = content?.supported_locales?.map(normalizeLocaleString) || [];
    const selectedLocale = url?.substring(url.indexOf('=') + 1);

    if (supportedLocales.includes(normalizeLocaleString(selectedLocale))) {
      this.languageRedirect(locale);
    } else {
      $('body.course-content').addClass('foundry');
      this.setState({ showLanguageModal: true });
    }
  };

  onCancel = () => {
    $('body.course-content').removeClass('foundry');
    this.setState({ showLanguageModal: false });
  }

  onConfirm = () => {
    window.location.href = sanitizeUrl(`${window.location.origin}${window.location.pathname}?locale=en`);
  }

  closeFrame = () => {
    window.location.href = sanitizeUrl(`/student/dashboard/close_frame${window.location.search}`);
  }

  renderMenu = () => {
    const { locales } = this.props;

    return (
      <div className="dropdown nav-cog-menu" id="navbar">
        <button className="btn btn-secondary dropdown-toggle" type="button" id="cog-menu-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label={i18n.t('Main menu')} onKeyDown={this.handleMenuKeyDown}>
          <FAIcon icon="gear" additionalClasses="menu-icon" />
        </button>

        <div className="dropdown-menu main-dropdown" aria-labelledby="cog-menu-button">
          <HelpModal className="dropdown-item" customTextEnabled={this.props.customTextEnabled} customText={this.props.customText} />

          <a className="dropdown-item" id="in_course-terms_of_service" href="https://everfi.com/terms/" target="_blank" tabIndex="-1" rel="noopener noreferrer">
            <FAIcon icon="file" additionalClasses="m-l-sm" />{i18n.t('Terms of Service')}
          </a>

          <a className="dropdown-item" id="in_course-accessibility" href="https://everfi.com/accessibility/" target="_blank" tabIndex="-1" rel="noopener noreferrer">
            <FAIcon icon="wheelchair" additionalClasses="m-l-sm" />{i18n.t('Accessibility')}
          </a>

          {locales && locales.length > 1 ?
            <div className="dropdown-submenu language-item">
              <a href="#" className="dropdown-item dropdown-toggle" id="language-menu-button" aria-haspopup="true" aria-expanded="false" aria-label={i18n.t('Language')} tabIndex="-1" onKeyDown={this.handleSubMenuKeyDown}>
                <FAIcon icon="language" />{i18n.t('Language')}<span><span className="caret" /></span>
              </a>
              <div className="dropdown-menu language-menu">
                {locales.map((locale) =>
                  <a className="dropdown-item" role="menuitem" onClick={evt => this.checkLocales(locale, evt)} href="#" tabIndex="-1">{locale.current ? <FAIcon icon="check" /> : null}{locale.name}</a>
                )}
              </div>
            </div> :
            null}

          <a className="dropdown-item" onKeyDown={this.handleHomeKeyUp} id="in_course-home" onClick={this.closeFrame} href="#">
            <FAIcon icon="house-chimney" additionalClasses="m-l-sm" />{i18n.t('Home')}
          </a>

        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderMenu()}
        <LanguageUnavailableModal
          onCancel={this.onCancel}
          onConfirm={this.onConfirm}
          show={this.state.showLanguageModal}
        />
      </>
    );
  }
}
