import ReactOnRails from 'react-on-rails';
import LoginLink from './components/login_link';
import LoginModal from './components/landing_page/LoginModal';
import PlaylistsWidget from './components/landing_page/PlaylistsWidget';
import IncentivesWidgetWrapper from './components/landing_page/IncentivesWidgetWrapper';
import RecommendationWidgetWrapper from './components/landing_page/RecommendationWidgetWrapper';
import FeaturedContentWidget from './components/landing_page/FeaturedContentWidget';
import FeaturedContentVideoWidget from './components/landing_page/FeaturedContentVideoWidget';
import { InteractiveNotifications } from 'reactifi/dist/es/components/InteractiveNotifications';
import LearnerDashboardApp from './apps/LearnerDashboard/';
import TrueLearnerDashboardApp from './apps/TrueLearnerDashboard/';
import ContentLibraryApp from './apps/ContentLibrary/';
import HelpModal from './components/help_modal';
import MenuDropdown from './components/MenuDropdown';
import { SidebarMenu } from 'reactifi/dist/es/components/sidebar/sidebar-menu';
import { HeaderMenu } from 'reactifi/dist/es/components/HeaderMenu';
import { SystemNotifications } from 'reactifi/dist/es/components/system-notifications';
import { ProgressSpinner } from 'reactifi/dist/es/components/progress-spinner';
import ContentApp from '../../externals/content-player';
import ContentWrapperApp from './apps/ContentWrapper/';
import Embed from './apps/ContentWrapper/components/Embed';
import ContentLegalCustomizations from './apps/LearnerDashboard/components/ContentLegalCustomizations';
import { AutoLogout } from 'reactifi/dist/es/components/AutoLogout';
import { RailsReactHookWrapper } from 'reactifi/dist/es/components/RailsReactHookWrapper';
import { LanguageDropdown } from 'reactifi/dist/es/components/LanguageDropdown';
import { NextHeaderWrapper } from './components/NextHeaderWrapper';
import InactiveOrganization from './components/InactiveOrganization';
require('./startup');

ReactOnRails.register({
  LoginLink,
  LoginModal,
  PlaylistsWidget,
  FeaturedContentWidget,
  FeaturedContentVideoWidget,
  InactiveOrganization,
  IncentivesWidgetWrapper,
  InteractiveNotifications,
  LearnerDashboardApp,
  TrueLearnerDashboardApp,
  ContentLibraryApp,
  ContentWrapperApp,
  HelpModal,
  HeaderMenu,
  NextHeaderWrapper,
  LanguageDropdown,
  SidebarMenu,
  MenuDropdown,
  ProgressSpinner,
  ContentApp,
  ContentLegalCustomizations,
  Embed,
  AutoLogout,
  RailsReactHookWrapper,
  SystemNotifications,
  RecommendationWidgetWrapper
});
