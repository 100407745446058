import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { i18n } from 'reactifi/dist/es/i18n/translate';
import { DisplayWidthForCards } from 'reactifi/dist/es/components/Layout/Display/DisplayWidthForCards';
import { DisplayMaxWidthWide } from 'reactifi/dist/es/components/Layout/Display/DisplayMaxWidthWide';
import { CardsModule } from 'reactifi/dist/es/components/Layout/Modules/CardsModule';
import { CardsList } from 'reactifi/dist/es/components/Layout/Modules/components/CardsList';
import { PresentationCard } from 'reactifi/dist/es/components/presentation-card/component';
import { getData } from 'reactifi/dist/es/json-api/JsonApiUtils';
import clipboardSrc from '../../../../../images/clipboard.svg';
import { sanitizeUrl } from '@braintree/sanitize-url';

const RecommendationWidget = (props) => {
  const { orientation = 'horizontal', program } = props;

  const [recommendations, setRecommendations] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const isVertical = orientation === 'vertical';

  const loadRecommendations = async () => {
    const { data } = await getData(`/api/data/user_recommendations?program_id=${program.id}`);

    setRecommendations(data);
    setLoaded(true);
  };

  useEffect(() => {
    loadRecommendations();
  }, []);

  const renderWidgetHeader = () => (
    <Fragment>
      <h2 className="title">{i18n.t('Recommended Modules')}</h2>
      <p className="description m-b-30">
        {i18n.t('Based on your preferred content, here are some modules you might enjoy.')}
        &nbsp;
        <a
          className="branded-link"
          href={sanitizeUrl(`${window.location.origin}/student/dashboard/${program.slug}/content_library`)}
        >
          {i18n.t('View Full Library')}
        </a>
      </p>
    </Fragment>
  );

  const createRecommendationUrl = ({ playlist_slug, content_id, match_type }) => {
    const params = new URLSearchParams(window.location.search);
    params.set('recommended', 'yes');
    params.set('match_type', match_type);

    return sanitizeUrl(`/student/dashboard/${program.slug}/${playlist_slug}/${content_id}?${params.toString()}`);
  };

  const getRecommendationCardBody = ({ description, duration }) => {
    return (
      <Fragment>
        <p>{description}</p>
        <strong className="recommendation-card-time position-absolute pb-2">
          {duration} {i18n.t('min')}
        </strong>
      </Fragment>
    );
  };

  const renderRecommendationCard = (recommendation) => {
    const { id, name, image_url } = recommendation;

    const card = (
      <PresentationCard
        key={id}
        type="narrow"
        title={name}
        description={getRecommendationCardBody(recommendation)}
        data-object-type="recommendation"
        data-object-id={id}
        mediaSize="small"
        mediaPosition="right"
        mediaContent={
          <img src={image_url} alt="Card Image" className="icon-img" />
        }
        orientation="horizontal"
        buttonConfig={[
          {
            label: i18n.t('Start'),
            style: 'primary',
            rightIcon: 'arrow-right',
            href: createRecommendationUrl(recommendation),
            className: 'small',
            'data-action': 'start-content-recommendation'
          }
        ]}
      />
    );

    if (!isVertical) {
      return card;
    }

    return (
      <DisplayMaxWidthWide className="vertical-display">
        {card}
      </DisplayMaxWidthWide>
    );
  };

  const renderEmptyPlaceholder = () => {
    return (
      <div className="empty-placeholder px-3">
        <img className="empty-placeholder__image" src={clipboardSrc} />
        <p className="subtitle empty-placeholder__message mt-3 mb-0">
          {i18n.t(
            'Congrats, you’ve completed all modules! Check back later for future content.'
          )}
        </p>
      </div>
    );
  };

  const renderContent = () => {
    if (isLoaded && !recommendations.length) {
      return (
        <DisplayWidthForCards threeCardMax={true}>
          {renderWidgetHeader()}
          {renderEmptyPlaceholder()}
        </DisplayWidthForCards>
      );
    }

    if (isVertical) {
      return (
        <Fragment>
          {renderWidgetHeader()}
          <CardsList>{recommendations.map(renderRecommendationCard)}</CardsList>
        </Fragment>
      );
    }

    return (
      
      <DisplayWidthForCards threeCardMax={true}>
        {renderWidgetHeader()}
        <CardsModule
          collection={recommendations}
          isBranded={true}
          type="narrow"
          resultsDisplayed={false}
          showPageSizeDropdown={false}
          resetScroll={false}
          listClasses="p-b-0 m-b-0"
          wrapperClasses="m-b-30"
        >
          {(recommendation) => renderRecommendationCard(recommendation)}
        </CardsModule>
      </DisplayWidthForCards>
    
    );
  };

  return (
    <div className={`recommendations-widget ${orientation}`}>
      {renderContent()}
    </div>
  );
};

RecommendationWidget.propTypes = {
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  program: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    slug: PropTypes.string
  })
};

RecommendationWidget.displayName = 'RecommendationWidget';

export default RecommendationWidget;
