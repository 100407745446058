import React from 'react';
import PropTypes from 'prop-types';
import { Section } from 'reactifi/dist/es/components/Layout/Section';

const ProgramLearnerDashboardCustomization = ({ customization, program }) => {

  return (
    <Section className="program-custom-dashboard" type="fullwidth" noPadding={true}>
      <div
        dangerouslySetInnerHTML={{ __html: customization }}
        data-attribute="program-true-learner-dashboard-customization"
        data-object-type="program"
        data-object-id={program?.id}
        id="program-learner-dashboard-customization"
      />
    </Section>
  );
}

ProgramLearnerDashboardCustomization.propTypes = {
  customization: PropTypes.shape({
    enabled: PropTypes.bool,
    text: PropTypes.string
  }),
  program: PropTypes.shape({
    id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string])
  })
};

export default ProgramLearnerDashboardCustomization;
