import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery'
import Rails from '@rails/ujs';
import ResponseSetField from './response_set_field'
import FormControl from 'react-bootstrap/FormControl';
import { Button, Section } from 'reactifi';
import i18n from "lib/utility/i18n";

export default class ResponseSetForm extends React.Component {

  static propTypes = {
    answers: PropTypes.object,
    autoSubmit: PropTypes.bool,
    canClose: PropTypes.bool,
    display_location: PropTypes.string.isRequired,
    errors: PropTypes.object,
    location: PropTypes.string.isRequired,
    message: PropTypes.string,
    programId: PropTypes.string.isRequired,
    onSuccess: PropTypes.func,
    questions: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      selected: {},
      twoColumns: false,
      errors: {}
    }
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  }

  componentDidMount() {
    if (this.props.autoSubmit) {
      let response = this.responseSetTemplate();
      if ((this.props.answers !== null) && (this.props.answers !== undefined)) {
        Object.keys(this.props.answers).map((key, idx) => {
          if (key !== 'show') {
            response.response_set[key] = this.props.answers[key];
          }
        });
        this.saveResponse(response);
      } else {
        this.props.onSuccess(); // dismisses gateway
      }
    }

    Rails.refreshCSRFTokens();
  }

  selectedValue = name => this.state.selected[name];

  freeTextValue = name => this.props.answers?.[name] || null;

  getAnswer = (evt, slug) => {
    if (!evt) return null;

    return slug ? evt.id : evt.answer_text;
  };

  handleSelectChange = (evt, slug) => {
    const id = slug || evt.id;
    const answer = this.getAnswer(evt, slug);
    let selectedItems = this.state.selected;
    selectedItems[id] = answer;
    this.setState({ selected: selectedItems });
  }

  handleSubmit = event => {
    event.preventDefault();
    const response = this.constructResponseSetPayload();
    this.saveResponse(response);
  }

  handleFail = response => {
    let messages = response.errors.shift();
    let error_messages = {};

    if (messages) {
      Object.keys(messages).map((key, idx) => {
        error_messages[key] = messages[key].join(', ');
      })
    }
    this.setState({ errors: error_messages });
  }

  responseSetTemplate = () => ({
    location: this.props.location,
    display_location: this.props.display_location,
    programId: this.props.programId,
    response_set: {}
  });

  constructResponseSetPayload = () => {
    let response = this.responseSetTemplate();

    // 'selectable' items like dropdowns and checkboxes
    Object.keys(this.state.selected).map((key, idx) => {
      response.response_set[key] = this.state.selected[key];
    });

    // free-text items
    $.each($('form [data-question-type="free-text"]'), (idx, item) => {
      response.response_set[item.id] = item.value;
    });
    return response;
  };

  errorsForQuestion = name => (
    name && this.state.errors ?
      this.state.errors[name] :
      null
  );


  saveResponse = item => {
    $.post('/student/response_sets.json', item)
      .fail((data) => { this.handleFail(data.responseJSON) })
      .done((data) => {
        this.props.onSuccess();
      });
  };

  renderQuestion = question => (
    <>
      {!question.allow_from_url &&
        <ResponseSetField
          key={question.question_id}
          onSelectChange={this.handleSelectChange}
          selected={this.selectedValue(question.slug)}
          freeTextValue={this.freeTextValue(question.slug)}
          errors={this.errorsForQuestion(question.slug)}
          objectPrefix="response_set"
          {...question}
        />
      } </>
  );

  render() {
    const {
      autoSubmit,
      canClose,
      display_location,
      location,
      message,
      programId,
      questions
    } = this.props;

    if (autoSubmit) return null;

    if (this.isIE11) {
      // clone backdrop to prevent ie11 focus issue
      $('.modal-backdrop').remove();
      $('.login-reg-modal').parent().prepend('<div class="modal-backdrop fade in"></div>');
    }

    return (
      <div className="container-fluid foundry">
        <form onSubmit={this.handleSubmit} noValidate={true}>
          <FormControl type="hidden" name="authenticity_token" />
          <FormControl type="hidden" name="location" value={location} />
          <FormControl type="hidden" name="display_location" value={display_location} />
          <FormControl type="hidden" name="program_id" value={programId} />
          {message && <div className="m-b">{message}</div>}
          {questions.map(this.renderQuestion)}
          <Section>
            <Button
              aria-label={i18n.t('Next page')}
              branded={true}
              data-action="cancel"
              className="m-l-10 pull-right"
              label={i18n.t('Next')}
              style="primary"
              type="submit"
            />
            {canClose &&
              <Button
                aria-label={i18n.t('Cancel')}
                branded={true}
                data-action="cancel"
                className="pull-right"
                label={i18n.t('Cancel')}
                style="secondary"
                type="cancel"
              />
            }
          </Section>
        </form>
      </div>
    );
  }
}
