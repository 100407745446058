import React from 'react';
import PropTypes from 'prop-types';
import Platform from 'platform';
import i18n from "lib/utility/i18n";
import { FAIcon } from 'reactifi/dist/es/components/fa-icon';
import logo from '../../../../images/logo.png';
import {
  ConfirmationModal,
  ExplainerText
} from 'reactifi';

export default class HelpModal extends React.Component {
  static propTypes = {
    customText: PropTypes.string,
    customTextEnabled: PropTypes.bool,
    tabindex: PropTypes.string
  };

  static defaultProps = {
    tabindex: '-1'
  };
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false
    };
  }

  closeModal = () => {
    this.setState({ modalOpen: false })
    $('body.course-content').removeClass('foundry');
  };

  openModal = event => {
    event.preventDefault();
    $('body.course-content').addClass('foundry');
    this.setState({ modalOpen: true })
  };

  getSupportText = () => {
    const { customText, customTextEnabled } = this.props;

    return customTextEnabled && customText?.length ?
      <span dangerouslySetInnerHTML={{ __html: customText }} /> :
      (
        <ExplainerText>
          {i18n.t('For technical support with EVERFI course content, please email: ')}
          <a className="branded-link" href="mailto:learnersupport@everfi.com">learnersupport@everfi.com</a>
        </ExplainerText>
      );
  }

  render() {
    const { tabindex } = this.props;
    const { modalOpen } = this.state;

    return (
      <div id="helpModal">
        <a tabIndex={tabindex} href="#" className="help-modal-link" onClick={this.openModal}><FAIcon icon="circle-question" /><span>{i18n.t('Help')}</span></a>
        <ConfirmationModal
          renderAsLegacy={true}
          branded={true}
          canClickOff={true}
          confirmOnly={true}
          confirmButtonText={i18n.t('Close')}
          id="help-modal"
          modalSize="medium"
          onCancel={this.closeModal}
          onConfirm={this.closeModal}
          show={modalOpen}
          title={i18n.t('Need Help?')}
        >
          <div className="help-mail-to">
            {this.getSupportText()}
          </div>
          <ExplainerText>
            {i18n.t('Please provide an explanation of the issue you are having as well as the following information below as part of your support request: ')}
          </ExplainerText>
          <ExplainerText>
            <ul>
              <li>{i18n.t('Operating System: %s', { postProcess: 'sprintf', sprintf: [Platform.os.family] })}</li>
              <li>{i18n.t('Browser: %s', { postProcess: 'sprintf', sprintf: [navigator.userAgent] })}</li>
              <li>{i18n.t('Device: %s', { postProcess: 'sprintf', sprintf: [navigator.platform] })}</li>
            </ul>
          </ExplainerText>

          <img src={logo} alt={i18n.t('EVERFI logo')} />
        </ConfirmationModal>
      </div>
    )
  }
}
