import React from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal } from 'reactifi';
import i18n from "lib/utility/i18n";

const LanguageUnavailableModal = props => {
  return (
    <ConfirmationModal
      {...props}
      branded={true}
      cancelButtonText={i18n.t('No')}
      confirmButtonText={i18n.t('Yes')}
      id="language-unavailable"
      title={i18n.t('Course not available in preferred language.')}
    >
      {i18n.t('This course is not available in your preferred language. Would you like to take this course in English?')}
    </ConfirmationModal>
  );
};

LanguageUnavailableModal.propTypes = {
  cancelButtonText: PropTypes.string,
  closeWithConfirm: PropTypes.func,
  confirmButtonText: PropTypes.string,
  id: PropTypes.string,
  onCancel: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string
};

export default LanguageUnavailableModal;
