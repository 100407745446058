import React from 'react';

import connectionImg from '../../../../images/connection.png';

export default class InactiveOrganization extends React.Component {
  render() {
    return (
      <div
        style={{
          height: '92vh',
          backgroundColor: '#f4f8f9',
          paddingTop: '25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            width: '50%',
            backgroundColor: 'white',
            border: '1px solid #d3d3d3',
            padding: '25px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <h1
            style={{
              marginTop: '0',
              textAlign: 'center',
              color: '#494949',
              fontSize: '19px'
            }}
          >
            Inactive Program
          </h1>

          <img
            src={connectionImg}
            style={{ width: '110px', marginTop: '20px' }}
            alt="Inactive Program"
          />

          <p style={{ marginTop: '25px', fontSize: '16px' }}>
            The program you are trying to access belongs to an organization that is no
            longer active. At this time, the program cannot be accessed. Please reach
            out to the organization if you have any further questions.
          </p>
        </div>
      </div>
    );
  }
}
