require.context('../../../images', true)
import Rails from '@rails/ujs';

import 'jquery';

import 'reactifi/dist/styles.js';
import 'jstz';
import '../../lib/utility/set_time_zone';
import '../../lib/utility/ahoy_patch';
import '../../lib/utility/progress_spinner';
import '../../lib/utility/progress_spinner_unload';

Rails.start();

/*
	When a custom header is added, this toggles a hamburger menu for those items on mobile
*/

window.toggleCustomBurger = (e) => {
  var el = document.getElementsByClassName('custom-header-nav')[0];
  window.getComputedStyle(el).display === 'block' ? el.style.display = '' : el.style.display = 'block'; // eslint-disable-line no-unused-expressions
}

/* global WebFont */
$(document).ready(function () {
  var fonts = $.uniqueSort($('font').map(function () { return $(this).attr('face') }));
  if (fonts && fonts.length) {
    WebFont.load({ google: { families: fonts } });
  }
});
