import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Queue from '../../LearnerDashboard/components/Queue';
import LibraryHeader from './LibraryHeader';
import i18n from 'lib/utility/i18n';
import { Tabs, Tab } from 'react-bootstrap';
import { kebabCase } from 'lodash';

const PlaylistContents = ({ handleContentNav, initialSearch, items, onSearch, program, statusCounts }) => {
  const [activePage, setActivePage] = useState(1);
  const [activeKey, setActiveKey] = useState(0);
  const [completedItems, setCompletedItems] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const progressStatuses = [
    { key: 'inprogress', title: i18n.t('Not Completed') },
    { key: 'completed', title: i18n.t('Completed') }
  ];
  
  const handleTabChange = key => {
    setActivePage(1);
    setActiveKey(key);
    setCompletedItems(Number(key) === 1);
  }

  const getTabbedContent = () => {
    return (
      <Queue
        activePage={activePage}
        content={items}
        handleContentNav={handleContentNav}
        showIcons={!program.remove_content_images}
        setActivePage={setActivePage}
        completedItems={completedItems}
        handleTabChange={handleTabChange}
        completedButtonLabel={i18n.t('View Not Completed')}
        isLibrary={true}
        isSearching={isSearching}
      />
    );
  }

  const handleSearch = ({ searchValue }) => {
    handleTabChange(0);
    setIsSearching(true);
    onSearch(searchValue);
  }

  return (
    <>
      <LibraryHeader onSearch={handleSearch} initialSearch={initialSearch} />
      <div className="row playlist-row">
        <div className="container">
          <div className="row playlists clearfix">
            <div className="col-12">
              <Tabs
                activeKey={activeKey}
                className="row uppercase branded nav-tabs learner-tabs"
                data-attribute="content-library-tabs"
                data-object-type="playlist_items"
                data-object-id={program.id}
                id="content-library-tabs"
                onSelect={handleTabChange}
              >
                {progressStatuses.map((statusObj, index) => {
                  const kebabTabTitle = `${kebabCase(statusObj.title)}-tab`;
                  return (
                    <Tab
                      key={statusObj.key}
                      data-action={`open-${kebabTabTitle}`}
                      data-attribute={kebabTabTitle}
                      data-object-type="playlist_items"
                      data-object-id={program.id}
                      eventKey={index}
                      title={`${statusObj.title} (${statusCounts[statusObj.key]})`}
                    >
                      {getTabbedContent()}
                    </Tab>
                  );
                })
                }
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PlaylistContents.propTypes = {
  handleContentNav: PropTypes.func,
  initialSearch: PropTypes.string,
  items: PropTypes.array,
  onSearch: PropTypes.func,
  program: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    remove_content_images: PropTypes.bool,
    text_customization: PropTypes.shape({
      activities_text: PropTypes.string
    })
  }).isRequired,
  statusCounts: PropTypes.shape({
    inprogress: PropTypes.number,
    completed: PropTypes.number
  })
};

export default PlaylistContents;
