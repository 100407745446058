import PropTypes from 'prop-types';
import React from 'react';
import i18n from "lib/utility/i18n";
import { FAIcon } from 'reactifi/dist/es/components/fa-icon';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default class FeaturedContentWidget extends React.Component {
  static propTypes = {
    program: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      playlists: PropTypes.array.isRequired,
      program_playlists: PropTypes.array.isRequired
    }),
    playlist_slug: PropTypes.string.isRequired
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
  }

  render() {
    let playlist = this.props.program.playlists.find((pl) => (
      pl.slug === this.props.playlist_slug
    ))

    if (!playlist) return <h2>{i18n.t('No Playlist')}</h2>

    let featured_id = this.props.program.program_playlists.find((pp) => (
      pp.playlist_id === playlist.id
    )).featured_content_id

    let featured_content = playlist.content.find((cont) => (
      cont.id === featured_id
    ))

    if (!featured_content) return <h2>{i18n.t('No Content')}</h2>;

    const url = sanitizeUrl(`/student/dashboard/${this.props.program.slug}?playlist=${playlist.id}`);

    return (
      <div className="featured-content">
        <a href={url}>
          <div className="content">
            <div className="header">
              <h2>{featured_content.name}</h2><span>{i18n.t('Explore now')} <FAIcon icon="circle-play" /></span>
            </div>
            <a href={url}>
              <img src={sanitizeUrl(featured_content.landing_page_teaser_fallback_image)} className="img-fluid mx-auto icon" />
            </a>
          </div>
        </a>
      </div>);
  }
}
