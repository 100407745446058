import PropTypes from 'prop-types';
import React from 'react';

const ItemIcon = ({ icon_url, id, stroke, fill = "#FFF" }) => (
  <svg className="item-icon" aria-hidden="true">
    <defs>
      <pattern id={`icon-image-${id}`} patternUnits="userSpaceOnUse" height="78" width="78" />
    </defs>
    {stroke ?
      <circle id={`top-${id}-bg`} cx="40" cy="40" r="37" fill={`${fill}`} stroke="#676a6c" className="top"
        strokeWidth="1px"
        strokeDasharray="565.48"
        strokeDashoffset="0"
      />
      : null}
    <image x="15" y="15" height="50" width="50" xlinkHref={icon_url} />
    <circle id={`top-${id}`} cx="40" cy="40" r="36" fill={`url(#icon-image-${id})`} className="top" />
  </svg>
)

ItemIcon.propTypes = {
  icon_url: PropTypes.string.isRequired,
  id: PropTypes.string,
  fill: PropTypes.string,
  stroke: PropTypes.bool
};

export default ItemIcon;
