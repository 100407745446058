import PropTypes from 'prop-types';
import React from 'react';
import { SearchComponent } from 'reactifi/dist/es/components/Form/SearchComponent';
import i18n from 'lib/utility/i18n';

const LibraryHeader = ({ initialSearch, onSearch }) => {
  return (
    <div className="row playlist-header clearfix">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7">
            <div className="row">
              <div className="col-12">
                <h1 className="title" data-attribute="name" data-object-type="playlists">
                  {i18n.t('Content Library')}
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="description m-b-40" data-attribute="description" data-object-type="playlists">
                  {i18n.t('Explore or search the content library to find what matters to you.')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-5 m-t-30">
            <SearchComponent
              value={initialSearch}
              placeholder={i18n.t('Search by topic or keyword')}
              searchAction={onSearch}
              data-action="search-library"
              data-field="name-slug-description"
              data-object-type="playlist_items"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

LibraryHeader.propTypes = {
  initialSearch: PropTypes.string,
  onSearch: PropTypes.func
};

export default LibraryHeader;
