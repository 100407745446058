import { readEntityById, readLocal, ApiUtil } from 'reactifi';

export function loadProgram(id) {
  return readEntityById(id, 'programs', ['account', 'questions']);
}

export function loadGateways(program_id, promptCounts) {
  const queryParams = { program_id, ...promptCounts };
  const gatewaysUrl = `/api/data/gateways?${Object.entries(queryParams).map(([ key, value ]) => `${key}=${value}`).join('&')}`;

  return async dispatch => {
    const data = await ApiUtil.loadApiData(gatewaysUrl, false);
    dispatch(readLocal(data));
  };
}
