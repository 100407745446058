import React from 'react';
import PropTypes from 'prop-types';
import { PaginationModule } from 'reactifi';
import Item from './Item';

const PAGE_SIZE = 10;

const PaginatedContent = (props) => {
  const { activePage, collection, handleContentNav, setActivePage, showIcons, processStatus } = props;

  const content = collection.slice(
    (activePage * PAGE_SIZE) - PAGE_SIZE,
    activePage * PAGE_SIZE
  );

  return (
    <PaginationModule
      activePage={activePage}
      collection={content}
      collectionMeta={{ total_count: collection.length }}
      isBranded={true}
      pageSize={PAGE_SIZE}
      resultsDisplayed={false}
      setActivePage={setActivePage}
    >
      {content.map(content => {
        return (
          <Item
            key={content.id}
            content={content}
            handleContentNav={handleContentNav}
            showIcons={showIcons}
            processStatus={processStatus}
          />
        );
      })}
    </PaginationModule>
  )
}

PaginatedContent.propTypes = {
  activePage: PropTypes.number,
  collection: PropTypes.array.isRequired,
  handleContentNav: PropTypes.func,
  setActivePage: PropTypes.func,
  showIcons: PropTypes.bool,
  processStatus: PropTypes.string
}

export default PaginatedContent;
