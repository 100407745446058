import PropTypes from 'prop-types';
import React from 'react';
import sample from 'lodash/sample';
import i18n from "lib/utility/i18n";
import { FAIcon } from 'reactifi/dist/es/components/fa-icon';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default class FeaturedContentVideoWidget extends React.Component {
  static propTypes = {
    program: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      program_playlists: PropTypes.array.isRequired,
      playlists: PropTypes.array.isRequired
    }),
    playlist_slug: PropTypes.string
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
  }

  render() {
    let playlist = null

    if (this.props.playlist_slug) {
      playlist = this.props.program.playlists.find((pl) => (
        pl.slug === this.props.playlist_slug
      ))
    }

    if (!playlist) {
      playlist = sample(this.props.program.playlists)
    }

    let featured_id = this.props.program.program_playlists.find((pp) => (
      pp.playlist_id === playlist.id
    )).featured_content_id

    let featured_content = playlist.content.find((cont) => (
      cont.id === featured_id
    ))

    if (!featured_content) return <h2>{i18n.t('No Content')}</h2>;
  
    const url = sanitizeUrl(`/student/dashboard/${this.props.program.slug}/${playlist.slug}`);

    return (<div className="featured-content">
      <a href={url}>
        <div className="content">
          <div className="header">
            <h2>{featured_content.name}</h2><span>{i18n.t('Explore now')} <FAIcon icon="circle-play" /></span>
          </div>
          <video className="object hoverable" poster={featured_content.landing_page_teaser_fallback_image} autoPlay="true">
            <source src={sanitizeUrl(featured_content.landing_page_teaser_mp4_url)} type="video/mp4" />
            <source src={sanitizeUrl(featured_content.landing_page_teaser_ogv_url)} type="video/ogv" />
            <source src={sanitizeUrl(featured_content.landing_page_teaser_webm_url)} type="video/webm" /> {i18n.t("Your browser doesn't support HTML5 video tag.")}
          </video>
        </div>
      </a>
    </div>)
  }
}
