import { JsonApiDecorator } from 'reactifi/dist/es/json-api/JsonApiDecorator';
import { constants } from '../constants';
import { isIncentiveActive } from '../../../common/functions';

/**
 * @param {object} playlist
 *
 * @returns `undefined` if `playlist` or `incentives` is `falsy` OR
 * @returns an array of incentives that are filtered by date range and `archived = false`
 */
const getActiveIncentives = playlist => playlist?.incentives?.filter(isIncentiveActive);

const mapPlaylistIncentives = (playlist, incentive_activities) => {
  const activeIncentives = getActiveIncentives(playlist);

  if (!activeIncentives?.length) return;

  playlist.incentive = activeIncentives[0];
  if (incentive_activities && playlist.incentive) {
    const playlistActivities = incentive_activities.where(ia => String(ia.incentive_id) === String(playlist.incentive.id));
    if (playlistActivities?.length) {
      // only one activity should match the playlist incentive
      playlist.incentive.incentive_activity = playlistActivities[0];
    }
  }
}

const addCountsToPlaylist = (playlist, progressStatus) => {
  if (progressStatus === constants.COMPLETED) {
    playlist.completedCount++;
  } else {
    playlist.startedCount++;
  }
};

const mapPlaylist = (playlist, userContent, progress, incentive_activities) => {
  playlist.startedCount = 0;
  playlist.completedCount = 0;
  playlist.content.forEach(content => {
    content.user_content = userContent.find(uc => String(uc.content_id) === String(content.id));

    if (!content.user_content) return;

    if (content.user_content.progress_id) {
      content.user_content.progress = progress && progress.find(content.user_content.progress_id);
      if (content.user_content.progress) {
        content.user_content.percent_complete = (content.user_content.progress.units_completed * 100) / content.user_content.progress.total_units;
        addCountsToPlaylist(playlist, content.user_content.progress.status);
      } else {
        addCountsToPlaylist(playlist, content.user_content.state);
      }
    } else {
      addCountsToPlaylist(playlist, content.user_content.state);
    }
  });
  playlist.allCompleted = playlist.completedCount === playlist.content.length;

  mapPlaylistIncentives(playlist, incentive_activities);
}

const getPlaylists = (apiStore, props, ownProps) => {
  const {
    incentive_activities,
    playlists,
    progress,
    user_content
  } = apiStore;

  if (!playlists) {
    props.program.playlists = [];
    return;
  }

  props.program.playlists = playlists.all({ incentives: 'incentive_activities', content: true });
  if (user_content) {
    props.program.playlists.forEach(playlist => mapPlaylist(playlist, user_content.all(), progress, incentive_activities));
  }
  if (ownProps.playlistId) {
    props.playlist = props.program.playlists.find(playlist => playlist.id === String(ownProps.playlistId));
    props.program.playlists = props.program.playlists.filter(playlist => playlist.id !== String(ownProps.playlistId));
  }
};

const getDiagnostics = (apiStore, props, ownProps) => {
  const { diagnostics, incentive_activities } = apiStore;

  if (!diagnostics) {
    props.program.diagnostics = [];
    return;
  }

  props.program.diagnostics = diagnostics.all({ incentives: 'incentive_activities' });
  props.program.diagnostics.forEach(diagnostic => {
    if (diagnostic.incentives && diagnostic.incentives.length) {
      diagnostic.incentive = diagnostic.incentives[0];
      if (incentive_activities) {
        diagnostic.incentive.incentive_activities = incentive_activities.where(ia => String(ia.incentive_id) === diagnostic.incentive.id);
        if (diagnostic.incentive.incentive_activities) {
          diagnostic.incentive.incentive_activity = diagnostic.incentive.incentive_activities[0];
        }
      }
    }
  });
  if (ownProps.diagnosticId) {
    props.diagnostic = props.program.diagnostics.find(diagnostic => diagnostic.id === String(ownProps.diagnosticId));
  }

  // map other playlists incentives even if there is no user_content
  if (props.program.playlists) props.program.playlists.forEach(playlist => mapPlaylistIncentives(playlist, incentive_activities));
};

const getPersonalizedPlaylists = (apiStore, props, ownProps) => {
  const {
    content,
    incentive_activities,
    personalized_playlists,
    playlist_items,
    progress,
    user_content
  } = apiStore;

  if (personalized_playlists) {
    props.playlist = personalized_playlists.find(String(ownProps.personalizedPlaylistId), 'content');

    // map personalized playlist content from playlist_items
    const playlistItems = playlist_items.where(item => String(item.personalized_playlist_id) === String(ownProps.personalizedPlaylistId));
    const playlistContent = playlistItems.map(item => content.find(String(item.content_id)));
    props.playlist.content = playlistContent;

    // map program diagnostics
    props.program.diagnostics = props.program.diagnostics.filter(diagnostics => String(diagnostics.id) !== String(props.playlist.diagnostic_id));

    if (user_content) mapPlaylist(props.playlist, user_content.all(), progress, incentive_activities);
  }
};

export default function mapStateToProps(state, ownProps) {
  const props = {};
  const stateApiDecorator = new JsonApiDecorator(state.api);
  const {
    gateways,
    incentives,
    programs,
    'customization-dashboard_flexible_area_customizations': dashboardCustomizations,
    'customization-text_customizations': textCustomizations
  } = stateApiDecorator;

  if (programs) {
    props.program = programs.first({ text_customization: true, account: true });

    const allTextCustomizations = textCustomizations ? textCustomizations.all() : [];
    props.program.text_customization = allTextCustomizations.find(customization => String(customization.program_id) === String(props.program.id));

    getPlaylists(stateApiDecorator, props, ownProps);
    getDiagnostics(stateApiDecorator, props, ownProps);
    getPersonalizedPlaylists(stateApiDecorator, props, ownProps);
  }

  props.dashboardCustomizationId = ownProps.dashboardCustomizationId;
  props.dashboardCustomization = dashboardCustomizations?.first() || null;

  props.gateways = gateways?.all() || [];
  props.incentives = incentives?.all() || [];
  props.incentiveId = props.playlist?.incentive?.id ||
    props.diagnostic?.incentive?.id ||
    props.incentives?.[0]?.id ||
    null;

  return props;
}
