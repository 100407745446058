import { JsonApiDecorator } from 'reactifi/dist/es/json-api/JsonApiDecorator';
import orderBy from 'lodash/orderBy';

export default function mapStateToProps(state, ownProps) {
  const props = {};
  const stateApiDecorator = new JsonApiDecorator(state.api);

  const {
    programs,
    program_playlists,
    playlists,
    diagnostics,
    program_diagnostics,
    'customization-dashboard_flexible_area_customizations': dashboardCustomizations,
    'customization-text_customizations': textCustomizations
  } = stateApiDecorator;

  if (programs) {
    props.program = programs.first({ text_customization: true, account: true });
    if (props.program.display_widgets_enabled) {
      props.programDashboardCustomization = props.program.display_widgets_text;
    }
    const allTextCustomizations = textCustomizations ? textCustomizations.all() : [];
    props.program.text_customization = allTextCustomizations.find(customization => String(customization.program_id) === String(props.program.id));

    if(playlists && program_playlists){
      const orderedProgramPlaylists = orderBy(program_playlists.all().map(({ playlist_id: playlistId, position }) => ({ playlistId, position })), ['position']).slice(0, 3) || [];
      let filteredPlaylist = playlists.all();
      props.program.playlists = [];
      orderedProgramPlaylists.forEach((programPlaylist) => {
        props.program.playlists.push(filteredPlaylist.find((playlist) => {
          return String(playlist.id) === String(programPlaylist.playlistId);
        }));
      });
    }

    if(diagnostics && program_diagnostics) {
      props.program.diagnostic = diagnostics.all().find((diagnostic) => {
        return String(diagnostic.id) === String(program_diagnostics.all().find(programDiagnostic => programDiagnostic.position === 1)?.diagnostic_id);
      });
    }
  }

  props.dashboardCustomizationId = ownProps.dashboardCustomizationId;
  props.dashboardCustomization = dashboardCustomizations?.first() || null;

  return props;
}
