function evalufiConfig(){
  let appDataElement = document.querySelector('meta[name=application_data]');
  if(appDataElement && appDataElement.attributes.content) {
    let appData = JSON.parse(appDataElement.attributes.content.value)
    return { endpointHost: appData.evalufi_url, endpointPath: 'api/v1' }
  }
}

export default {
  evalufi: evalufiConfig() 
};