import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserUpdate } from 'reactifi/dist/es/browserUpdate';
import Cookies from 'js.cookie';
import { ProgressSpinner } from 'reactifi/dist/es/components/progress-spinner';
import { injectCustomerColorsCSS } from 'lib/utility/helpers';
import { getGatewayPromptCounts } from '../../../common/functions';
import { FROM_LIBRARY_QUERY_STRING } from '../../../common/constants';
import * as contentLibraryActions from '../actions/contentLibraryActions';
import mapStateToProps from '../store/contentLibraryMapper';
import OtherPlaylists from '../../LearnerDashboard/components/OtherPlaylists';
import DashboardCustomization from '../../LearnerDashboard/components/DashboardCustomization';
import GatewayModal from '../../LearnerDashboard/components/GatewayModal';
import PlaylistContents from '../components/PlaylistContents';

const URL_SEARCH_PARAM = 'search';

const mapDispatchToProps = dispatch => bindActionCreators({ ...contentLibraryActions }, dispatch);

const ContentLibraryContainer = props => {
  const { dashboardCustomization, dashboardCustomizationId, gateways, loadCustomization, loadPlaylistItems, loadPlaylists, loadUserContent, loadIncentives, loadIncentiveActivities, playlistContents, program, programId, selectProgram, statusCounts, ui, userId } = props;
  const [activeSearch, setActiveSearch] = useState((new URLSearchParams(window.location.search)).get(URL_SEARCH_PARAM));
  const [activeGateway, setActiveGateway] = useState(null);
  const [onGatewayFinished, setOnGatewayFinished] = useState(null);

  useEffect(() => {
    BrowserUpdate();
    selectProgram(programId);
    if (dashboardCustomizationId) loadCustomization(dashboardCustomizationId);
    loadPlaylists(programId);
    loadUserContent(programId, userId);
    loadGateways();
    loadIncentives(programId);
    loadIncentiveActivities(programId, userId);
  }, []);

  useEffect(() => {
    loadPlaylistItems(programId, activeSearch);
    updateSearchQueryParam(activeSearch);
  }, [activeSearch]);

  useEffect(() => {
    if (program) injectCustomerColorsCSS(program.primary_color, program.secondary_color);
  }, [program?.primary_color, program?.secondary_color]);

  const loadGateways = async () => {
    await props.loadGateways(programId, getGatewayPromptCounts());
    handleDashboardLoadGateways();
    if (ui.closedContent) {
      Cookies.remove('closed_content_id');
      handleAfterContentGateways();
    }
  }

  const handleAfterContentGateways = () => setGateway('playlist:afterContentReturn');
  const handleBeforeContentGateways = onFinish => setGateway('playlist:beforeContentStart', onFinish);
  const handleDashboardLoadGateways = () => setGateway('dashboard:load');

  const setGateway = (gatewayAt, onFinish = () => { }) => {
    if (gateways.length > 0) {
      const gateway = gateways.find(gateway => gateway.at === gatewayAt);
      if (gateway) {
        setActiveGateway(gateway);
        setOnGatewayFinished(() => onFinish);
      } else {
        onFinish();
      }
    } else {
      onFinish();
    }
  }

  const closeGateway = () => {
    if (activeGateway?.next) {
      const nextGateway = gateways.find(gw => gw.type === activeGateway.next);
      if (nextGateway) {
        setActiveGateway(nextGateway);
      } else {
        setActiveGateway(null);
        setOnGatewayFinished(null);
        onGatewayFinished();
      }
    } else {
      setActiveGateway(null);
      setOnGatewayFinished(null);
      onGatewayFinished();
    }
  }

  const updateSearchQueryParam = value => {
    const url = new URL(window.location);

    if (value) {
      url.searchParams.set(URL_SEARCH_PARAM, activeSearch);
    } else {
      url.searchParams.delete(URL_SEARCH_PARAM);
    }

    window.history.replaceState(null, '', url.toString());
  }

  const goToStudentUrl = content => {
    const playlistSlug = content.personalized_playlist_slug || content.playlist_slug;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.append(FROM_LIBRARY_QUERY_STRING, true);

    window.location = `/student/dashboard/${program.slug}/${playlistSlug}/${content.id}?${urlParams.toString()}`;
  }

  const handleContentNav = content => {
    handleBeforeContentGateways(() => goToStudentUrl(content));
  }

  if (!program) return <ProgressSpinner />;

  return (
    <div className="learner-dash-container primary secondary">
      <PlaylistContents
        items={playlistContents}
        handleContentNav={handleContentNav}
        program={program}
        onSearch={setActiveSearch}
        initialSearch={activeSearch}
        statusCounts={statusCounts}
      />
      <div className="row">
        <div className="border-bottom" />
      </div>
      <div className="row other-playlists-row">
        <OtherPlaylists program={program} />
      </div>
      {activeGateway && (
        <GatewayModal
          {...ui}
          gatewayToPresent={activeGateway}
          onModalClose={closeGateway}
          programAuthCaps={program.auth_capabilities}
          programId={program.id}
        />
      )}
      <DashboardCustomization customization={dashboardCustomization} program={program} />
    </div>
  );
}

ContentLibraryContainer.propTypes = {
  dashboardCustomization: PropTypes.shape({
    enabled: PropTypes.bool,
    text: PropTypes.string
  }),
  dashboardCustomizationId: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  gateways: PropTypes.array,
  loadCustomization: PropTypes.func,
  loadGateways: PropTypes.func,
  loadPlaylistItems: PropTypes.func,
  loadPlaylists: PropTypes.func,
  loadUserContent: PropTypes.func,
  loadIncentives: PropTypes.func,
  loadIncentiveActivities: PropTypes.func,
  playlist: PropTypes.object,
  playlistContents: PropTypes.array,
  playlistId: PropTypes.number,
  program: PropTypes.shape({
    auth_capabilities: PropTypes.object,
    diagnostics: PropTypes.array,
    id: PropTypes.string,
    playlists: PropTypes.array,
    primary_color: PropTypes.string,
    remove_content_images: PropTypes.bool,
    secondary_color: PropTypes.string,
    slug: PropTypes.string
  }),
  programId: PropTypes.number.isRequired,
  selectProgram: PropTypes.func,
  statusCounts: PropTypes.shape({
    inprogress: PropTypes.string,
    completed: PropTypes.string
  }),
  ui: PropTypes.shape({
    answers: PropTypes.array,
    authUrls: PropTypes.array,
    closedContent: PropTypes.string,
    current_uri_context: PropTypes.string.isRequired,
    foundryLinkUrl: PropTypes.string,
    loginText: PropTypes.string
  }),
  userId: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentLibraryContainer);
