import PropTypes from 'prop-types';
import React from 'react';
import GenericModal from 'lib/components/GenericModal';
import LoginBox from './login_box';
import { FAIcon } from 'reactifi/dist/es/components/fa-icon';

export default class LoginLink extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }

    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.linkContent = this.linkContent.bind(this)
  }

  open(e) {
    this.setState({ showModal: true })
    e.preventDefault()
  }

  close() {
    this.setState({ showModal: false });
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    css_class: PropTypes.string,
    login_text: PropTypes.string,
    program: PropTypes.object.isRequired,
    hideLogin: PropTypes.bool,
    authUrls: PropTypes.array.isRequired
  };

  linkContent() {
    let css = (this.props.css_class || '') + 'btn btn-primary btn-primary-branded capitalize';
    if (this.props.icon) {
      return <a onClick={this.open} className={css} href="#"><FAIcon icon={this.props.icon} /> {this.props.name}</a>

    }
    else {
      return <a onClick={this.open} className={css} href="#">{this.props.name}</a>
    }
  }

  render() {
    return (
      <div className="login-link">
        {this.props.hideLogin ? null : this.linkContent()}
        {
          this.state.showModal
            ? <GenericModal onClose={this.close} modalClass="login-reg-modal" canClose={true} >
              <LoginBox
                loginText={this.props.login_text}
                programId={this.props.program.id}
                authCaps={this.props.program.auth_capabilities}
                authUrls={this.props.authUrls}
                program={this.props.program}
              />
            </GenericModal>
            : null
        }
      </div>
    )
  }
}
