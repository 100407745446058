
// import mirrorCreator from 'mirror-creator';

export const actionTypes = {
  SELECT_CONTENT: 'SELECT_CONTENT',
  PROMPT_GATEWAY: 'PROMPT_GATEWAY',
  DISMISS_GATEWAY: 'DISMISS_GATEWAY',
  CLOSE_CONTENT: 'CLOSE_CONTENT',
  REQUEST_USER_CONTENT: 'REQUEST_USER_CONTENT',
  RECEIVE_USER_CONTENT: 'RECEIVE_USER_CONTENT',
  INVALIDATE_USER_CONTENT: 'INVALIDATE_USER_CONTENT',
  RECEIVE_GATEWAYS: 'RECEIVE_GATEWAYS',
  SCREEN_RESIZE: 'SCREEN_RESIZE',
  DEEP_LINKED: 'DEEP_LINKED'
};

export const constants = {
  INPROGRESS: 'inprogress',
  COMPLETED: 'completed'
}
