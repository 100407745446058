import PropTypes from 'prop-types';
import React from 'react';
import { Evaluations } from '../../../externals/evalufi.js';

export default class Survey extends React.Component {

  static propTypes = {
    language: PropTypes.string.isRequired,
    evalufiConfig: PropTypes.object.isRequired,
    responseSet: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
    containerSelector: PropTypes.string
  };

  constructor(props){
    super(props);
  }

  render() {
    return (
      <Evaluations
        language={this.props.language}
        endpointHost={this.props.evalufiConfig.endpointHost}
        endpointPath={this.props.evalufiConfig.endpointPath}
        accessToken={this.props.responseSet.relationships.access_token.data.id}
        responseSetId={this.props.responseSet.id}
        afterFinished={this.props.onFinished}
        containerSelector={this.props.containerSelector}/>
    )
  }
}
