import React from 'react';
import PropTypes from 'prop-types';
import { sanitize as sanitizeHTML } from "dompurify";

const DashboardCustomization = ({ customization, program }) => {
  if (!customization?.enabled) return null;

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizeHTML(customization.text) }}
      data-attribute="program-learner-dashboard-customization"
      data-object-type="program"
      data-object-id={program?.id}
      id="learner-dashboard-customization"
    />
  );
}

DashboardCustomization.propTypes = {
  customization: PropTypes.shape({
    enabled: PropTypes.bool,
    text: PropTypes.string
  }),
  program: PropTypes.shape({
    id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string])
  })
};

export default DashboardCustomization;
