import React from 'react';
import PropTypes from 'prop-types';
import { FloatingLabelFormField, FieldGroup } from 'reactifi';

const RegistrationQuestion = props => {
  const {
    children,
    formFieldAttributes
  } = props;

  return (
    <FieldGroup className="row">
      <FloatingLabelFormField {...formFieldAttributes}>
        {children}
      </FloatingLabelFormField>
    </FieldGroup>
  )
};

RegistrationQuestion.propTypes = {
  children: PropTypes.node,
  formFieldAttributes: PropTypes.object
};

export default RegistrationQuestion;
