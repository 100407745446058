import { Component } from 'react';
import PropTypes from 'prop-types';
import ContentApp from '../../../../../externals/content-player';
import i18n from "lib/utility/i18n";
import { injectCustomerColorsCSS } from 'lib/utility/helpers';
import debounce from 'lodash.debounce';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = true; // We need this for the proper course FA styles to be applied here for embed content 

class Embed extends Component {
  static propTypes = {
    accessToken: PropTypes.string,
    endpointHost: PropTypes.string,
    endpointPath: PropTypes.string,
    jwt: PropTypes.string,
    locales: PropTypes.arrayOf(PropTypes.string),
    primary_color: PropTypes.string,
    programId: PropTypes.string,
    programSlug: PropTypes.string,
    progressId: PropTypes.string,
    secondary_color: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.debouncedResize = debounce(this.onResize.bind(this), 500, { leading: false, trailing: true });
  }

  async componentDidMount() {
    injectCustomerColorsCSS(this.props.primary_color, this.props.secondary_color, true);

    this.setState({ loading: false }, this.subscribeResizeObserver);
    this.setupIframePageLoadedEvent();
  }

  subscribeResizeObserver = () => {
    if (typeof MutationObserver !== 'undefined') {
      var page = document.querySelector('html');
      var resizeObserver = new MutationObserver(this.debouncedResize);
      resizeObserver.observe(page, { attributes: true, childList: true, subtree: true });
    }
  }

  setupIframePageLoadedEvent = () => {
    window.addEventListener('message', e => {
      if (e.data.event_type === 'url-change') {
        window.parent.postMessage({ event_type: 'everfi-program-content-iframe-page-loaded' }, '*');
      }
    });
  }

  onResize = () => {
    var page = document.querySelector('html');
    var styles = window.getComputedStyle(page);
    window.parent.postMessage(
      {
        event_type: 'everfi-program-content-iframe-resized',
        width: styles.width,
        height: styles.height
      }, '*');
  }

  onComplete(){
    window.parent.postMessage('everfi-program-content-iframe-exited', '*');
  }

  render() {
    const hostUrl = window.location;
    const portalEndpoint = `${hostUrl.protocol}//${hostUrl.hostname}${hostUrl.port ? `:${hostUrl.port}` : ''}/api/data`;
    const portalQueryString = `program_slug=${this.props.programSlug}&program_id=${this.props.programId}`;

    if(this.state.loading){
      return <div/>;
    } else {
      return (
        <ContentApp
          endpointHost={this.props.endpointHost}
          endpointPath={this.props.endpointPath}
          iframed={false}
          progressId={this.props.progressId}
          accessToken={this.props.accessToken}
          jwt={this.props.jwt}
          onComplete={() => this.onComplete()}
          portalQueryString={portalQueryString}
          portalEndpoint={portalEndpoint}
          locale={i18n.language}
          locales={this.props.locales}
        />
      )
    }
  }
}

export default Embed;
