import React from 'react';
import PropTypes from 'prop-types';
import { RailsReactHookWrapper } from 'reactifi/dist/es/components/RailsReactHookWrapper';
import { injectCustomerColorsCSS } from '../../../lib/utility/helpers';

export const NextHeaderWrapper = props => {
  const { logo_hyperlink, primary_logo, primary_color, secondary_color, id, social_icon, auth_capabilities } = props?.program || {};
  const onLoginLoad = () => {
    injectCustomerColorsCSS(primary_color, secondary_color);
  };
  const idProvidersUrl = id ? `/api/data/programs/${id}/saml_urls.json` : null;

  return (
    <RailsReactHookWrapper
      onLoginLoad={onLoginLoad}
      idProvidersUrl={idProvidersUrl}
      socialIcon={social_icon}
      authCapabilities={auth_capabilities}
      logoOverrideUrl={primary_logo}
      logoOverrideHyperlink={logo_hyperlink}
      {...props}
    />
  )
}
NextHeaderWrapper.propTypes = {
  program: PropTypes.object
};