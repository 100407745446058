import ahoy from '@everfi/foundry-analytics-js';

// Fixes issue with cookies.js not parsing none UTF-8 characters.
// This is re-encoded back in the Event model.
ahoy._trackOld = ahoy.track; //this has to be different from the method name used in foundry-analytics
ahoy.track = function (name, properties) {
  if (properties) {
    if (properties.text) {
      properties.text = encodeURIComponent(properties.text);
    }
  }

  ahoy._trackOld(name, properties);
};
