import PropTypes from 'prop-types';
import React from 'react';
import GenericModal from 'lib/components/GenericModal'
import LoginBox from '../login_box';

export default class LoginModal extends React.Component {

  static propTypes = {
    program: PropTypes.object.isRequired,
    authUrls: PropTypes.array.isRequired
  }

  constructor(props){
    super(props);

    this.state = {
      showModal: true
    }

    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose(){
    this.setState({ showModal: false });
  }

  modal(show){
    return (
      <GenericModal
        show={show}
        id="landingPageLoginModal"
        onClose={this.handleModalClose}
        modalClass="login-reg-modal"
        canClose={true}>
        <LoginBox
          loginText={this.props.program.custom_login_text||this.props.program.account.login_text_translated}
          program={this.props.program}
          programId={this.props.program.id}
          authCaps={this.props.program.auth_capabilities}
          authUrls={this.props.authUrls}
        />
      </GenericModal>
    );
  }

  render() {
    return (
      <div>
        {
          this.modal(this.state.showModal)
        }
      </div>
    );
  }

}
