import { JsonApiDecorator } from 'reactifi/dist/es/json-api/JsonApiDecorator';
import { AlphabetizeArrayOfObjects } from 'reactifi/dist/es/utils/StringUtil';
import { constants } from '../../LearnerDashboard/constants';
import uniqBy from 'lodash/uniqBy';

const addStatusCounts = (counts, progressStatus) => {
  if (progressStatus === constants.COMPLETED) {
    counts.completed++;
  } else {
    counts.inprogress++;
  }
};

const mapPlaylistContentToUserContentAndStatus = (playlistContent, userContent, progress, statusCounts) => {
  playlistContent.user_content = userContent.find(uc => String(uc.content_id) === String(playlistContent.id));

  const { user_content } = playlistContent;

  if (!user_content) {
    statusCounts.inprogress++;
    return;
  }

  if (user_content.progress_id) {
    playlistContent.user_content.progress = progress?.find(user_content.progress_id);
    if (user_content.progress) {
      playlistContent.user_content.percent_complete = (user_content.progress.units_completed * 100) / user_content.progress.total_units;
      addStatusCounts(statusCounts, user_content.progress.status);
    } else {
      addStatusCounts(statusCounts, user_content.state);
    }
  } else {
    addStatusCounts(statusCounts, user_content.state);
  }
}

const mapPlaylistContents = (props, playlistItems = [], progress) => {
  const uniqueItems = uniqBy(playlistItems, 'content_id');
  let playlistContents = uniqueItems.map(item => Object.assign(item.content, {
    playlist_slug: item.playlist_slug,
    personalized_playlist_slug: item.personalized_playlist_slug
  }));
  playlistContents.forEach(content => mapPlaylistContentToUserContentAndStatus(content, props.userContent, progress, props.statusCounts));

  props.playlistContents = AlphabetizeArrayOfObjects(playlistContents, 'name');
}

const mapPlaylistIncentives = (playlist, incentives, incentiveActivities, programId) => {
  const programIncentives = incentives.where(incentive => String(incentive.program_id) === programId);
  const playlistIncentives = programIncentives.find(incentive => String(incentive.playlist_id) === playlist.id);

  if (playlistIncentives) {
    playlist.incentive = playlistIncentives;

    if (incentiveActivities) {
      const playlistIncentiveActivities = incentiveActivities.where(activity => String(activity.incentive_id) === playlist.incentive.id);
      playlist.incentive.incentive_activity = playlistIncentiveActivities?.length ? playlistIncentiveActivities[0] : undefined;
    }
  }
}

export default function mapStateToProps(state, ownProps) {
  let props = {};
  const stateApiDecorator = new JsonApiDecorator(state.api);
  const {
    gateways,
    playlist_items,
    playlists,
    programs,
    progress,
    'customization-dashboard_flexible_area_customizations': dashboardCustomizations,
    'customization-text_customizations': textCustomizations,
    user_content,
    incentives,
    incentive_activities
  } = stateApiDecorator;

  props.userContent = user_content?.all() || [];
  props.statusCounts = { inprogress: 0, completed: 0 };

  mapPlaylistContents(props, playlist_items?.all('content'), progress);

  if (programs) {
    props.program = programs.first({ text_customization: true, account: true });
    props.program.playlists = playlists?.all() || [];
    
    if (incentives) {
      props.program.playlists.forEach(playlist => mapPlaylistIncentives(playlist, incentives, incentive_activities, props.program.id));
    }

    const allTextCustomizations = textCustomizations ? textCustomizations.all() : [];
    props.program.text_customization = allTextCustomizations.find(customization => String(customization.program_id) === String(props.program.id));
  }

  props.dashboardCustomizationId = ownProps.dashboardCustomizationId;
  props.dashboardCustomization = dashboardCustomizations?.first() || null;
  props.gateways = gateways?.all() || [];

  return props;
}
