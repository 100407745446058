import { readLocal, selectEntity, readEntityById } from 'reactifi/dist/es/actions/coreApiActions';
import { readEntity } from 'reactifi/dist/es/actions/apiActions';
import { loadApiData } from 'reactifi/dist/es/utils/ApiUtil';

export function loadUserContent(programId, userId) {
  return async (dispatch, getState) => {
    await dispatch(readEntity('user_contents', 'external_access_token', { program_id: programId, user_id: userId }, null, { number: 1, size: 200 }));

    const { api: { user_content } } = getState();
    const progressIds = user_content ? [...new Set(user_content.data.map(uc => uc.attributes.progress_id).filter(id => id))] : [];

    if (progressIds.length) {
      const filterParams = progressIds.map(id => `filter[id][]=${id}`).join('&');
      const progressUrl = `/api/data/progress.json?${filterParams}`;
      const progressData = await loadApiData(progressUrl, false);

      dispatch(readLocal(progressData));
    }
  }
}

export function loadPlaylistItems(playlist_id) {
  return readEntity('playlist_items', 'playlist,content', { playlist_id }, null, { number: 1, size: 100 });
}

export function loadPersonalizedPlaylistItems(personalized_playlist_id) {
  return readEntity('playlist_items', 'personalized_playlist,content', { personalized_playlist_id }, null, { number: 1, size: 100 });
}

export function selectDiagnostic(dignosticId) {
  return selectEntity(dignosticId, 'diagnostics');
}

export function selectProgram(programId) {
  return selectEntity(programId, 'programs', ['account', 'text_customization']);
}

export function loadOtherPlaylists(program_id) {
  return readEntity('program_playlists', 'playlist,program.incentives', { program_id });
}

export function loadOtherDiagnostics(program_id) {
  return readEntity('program_diagnostics', 'diagnostic', { program_id });
}

export function loadIncentives(program_id, playlist_id) {
  return readEntity('incentives', '', { program_id, playlist_id });
}

export function loadIncentiveActivities(program_id, playlist_id, user_id, incentive_id) {
  return readEntity('incentive_activities', 'incentive', { program_id, playlist_id, user_id, incentive_id });
}

export function loadAllIncentiveActivities(program_id, user_id) {
  return readEntity('incentive_activities', 'incentive', { program_id, user_id });
}

export function loadGateways(program_id, promptCounts) {
  const queryParams = { program_id, ...promptCounts };
  const gatewaysUrl = `/api/data/gateways?${Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&')}`;

  return async dispatch => {
    const data = await loadApiData(gatewaysUrl, false);
    dispatch(readLocal(data));
  };
}

export function loadCustomization(id) {
  return readEntityById(id, 'customizations');
}
