import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserUpdate } from 'reactifi/dist/es/browserUpdate';
import ContentApp from '../../../../../externals/content-player';
import GatewayModal from '../../LearnerDashboard/components/GatewayModal';
import LanguageUnavailableModal from '../../LearnerDashboard/components/LanguageUnavailableModal';
import PlatformModal from 'lib/utility/platform_modal';
import i18n from "lib/utility/i18n";
import * as actionCreators from '../actions/contentWrapperActions';
import mapStateToProps from '../store/contentWrapperMapper';
import { injectCustomerColorsCSS } from 'lib/utility/helpers';
import { getGatewayPromptCounts, normalizeLocaleString } from '../../../common/functions';
import { sanitizeUrl } from '@braintree/sanitize-url';

class ContentWrapper extends Component {
  static propTypes = {
    accessToken: PropTypes.string,
    answers: PropTypes.object,
    dispatch: PropTypes.func,
    endpointHost: PropTypes.string,
    endpointPath: PropTypes.string,
    iframed: PropTypes.bool,
    jwt: PropTypes.string,
    locales: PropTypes.arrayOf(PropTypes.string),
    gateways: PropTypes.array,
    program: PropTypes.shape({
      id: PropTypes.string,
      primary_color: PropTypes.string,
      secondary_color: PropTypes.string,
      slug: PropTypes.string
    }),
    programId: PropTypes.string.isRequired,
    progressId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      modalWasClosed: false,
      loading: true
    };

    this.actions = bindActionCreators(actionCreators, this.props.dispatch);
  }

  async componentDidMount() {
    await this.actions.loadProgram(this.props.programId);
    this.actions.loadGateways(this.props.programId, getGatewayPromptCounts());

    const { program } = this.props;

    if (program) {
      injectCustomerColorsCSS(program.primary_color, program.secondary_color, true);
      this.setState({ loading: false });
    }

    BrowserUpdate();
  }

  modalClosed = () => {
    this.setState({ modalWasClosed: true });
  }

  exitCourse = () => {
    window.location.href = sanitizeUrl(`/student/dashboard/close_frame${window.location.search}`);
  }

  onConfirm = () => {
    const selectedLocale = 'en-US';
    localStorage.setItem('languageChecked', selectedLocale);
    window.location.href = sanitizeUrl(`${window.location.origin}${window.location.pathname}?locale=${selectedLocale}`);
  }

  render() {
    const languageChecked = localStorage.getItem('languageChecked');
    const noValidate = languageChecked && languageChecked === i18n.language;
    const locales = this.props.locales?.map(normalizeLocaleString) || [];
    const isLanguageAvailable = locales.includes(normalizeLocaleString(i18n.language));

    if (!isLanguageAvailable && !window.sessionStorage.getItem("dashboardChangedLocale") && !noValidate) {
      PlatformModal.open();
      return (
        <LanguageUnavailableModal
          show={true}
          onConfirm={this.onConfirm}
          onCancel={this.exitCourse}
        />
      )
    } else if (isLanguageAvailable &&
    (window.sessionStorage.getItem("dashboardChangedLocale"))) {
      window.sessionStorage.removeItem("dashboardChangedLocale");
    }

    const { program } = this.props;
    if (!program) return null;

    const demographicGateway = this.props.gateways.find(gateway =>  gateway.type === 'demographic');
    const hostUrl = window.location;
    const portalEndpoint = `${hostUrl.protocol}//${hostUrl.hostname}${hostUrl.port ? `:${hostUrl.port}` : ''}/api/data`;
    const portalQueryString = `program_slug=${program.slug}&program_id=${program.id}`;
    const locale = isLanguageAvailable ? i18n.language : 'en';

    if (this.state.loading) {
      return <div/>;
    } else if (!this.state.modalWasClosed && demographicGateway && demographicGateway.questions_show_at_first_activity.length > 0) {
      return (
        <GatewayModal
          answers={this.props.answers}
          gatewayToPresent={demographicGateway}
          programId={program.id}
          onModalClose={this.modalClosed}
        />
      )
    } else {
      return (
        <ContentApp
          endpointHost={this.props.endpointHost}
          endpointPath={this.props.endpointPath}
          iframed={this.props.iframed}
          progressId={this.props.progressId}
          accessToken={this.props.accessToken}
          jwt={this.props.jwt}
          onComplete={this.exitCourse}
          portalQueryString={portalQueryString}
          portalEndpoint={portalEndpoint}
          locale={locale}
          locales={this.props.locales}
        />
      )
    }
  }
}

export default connect(mapStateToProps)(ContentWrapper);
