import { readEntityById, selectEntity } from 'reactifi/dist/es/actions/coreApiActions';
import { readEntity } from 'reactifi/dist/es/actions/apiActions';

export function loadCustomization(id) {
  return readEntityById(id, 'customizations');
}

export function selectProgram(programId) {
  return selectEntity(programId, 'programs', ['account', 'text_customization']);
}


export function loadPlaylists(program_id) {
  return readEntity('program_playlists', 'playlist', { program_id });
}

export function loadDiagnostics(program_id) {
  return readEntity('program_diagnostics', 'diagnostic', { program_id });
}
