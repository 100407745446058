import { storage as Storage } from 'reactifi/dist/es/utils/Storage';
import i18n from 'lib/utility/i18n';
import { isWithinInterval } from 'date-fns';

export const getGatewayPromptCounts = () => {
  // build counts object for each gateway type, based on LS stored value
  return ['login', 'demographic', 'incentives', 'additional_fields'].reduce((countsObj, type) => {
    const key = `gatewayPromptCount-${type}`;
    countsObj[key] = Number(Storage.getItem(key)); // Number(null) === 0

    return countsObj;
  }, {});
}

export const isIncentiveActive = incentive => {
  // "non-existant" or archived -> inactive
  if (!incentive || incentive.archived) return false;

  // no boundary dates -> inactive
  if (!incentive.start_date || !incentive.end_date) return false;

  // has not started or already ended -> inactive
  return isWithinInterval(new Date(), { start: new Date(incentive.start_date), end: new Date(incentive.end_date) });
}

export const getLocaleVersion = (data, field) => data[`${field}_${i18n.language.toLowerCase().replace('-', '_')}`] ?? data[field];

export const normalizeLocaleString = lang => {
  // since languages could potentially come with hyphens or dashes, we need to normalize to compare
  return lang.replace('-', '_');
}
