import PropTypes from 'prop-types';
import React from 'react';
import { RadioGroupControl, SelectControl } from 'reactifi';
import FormGroup from 'react-bootstrap/FormGroup';
import i18n from "lib/utility/i18n";
import RegistrationQuestion from './RegistrationQuestion';

export default class ResponseSetField extends React.Component {

  static propTypes = {
    question_text: PropTypes.string,
    question_type: PropTypes.string,
    slug: PropTypes.string,
    answers: PropTypes.array,
    onSelectChange: PropTypes.func,
    selected: PropTypes.string,
    objectPrefix: PropTypes.string.isRequired,
    errors: PropTypes.string,
    freeTextValue: PropTypes.string,
    allow_from_url: PropTypes.bool,
    editable: PropTypes.bool,
    required: PropTypes.bool
  }

  getSharedFormFieldAttributes = () => {
    const {
      objectPrefix,
      question_text,
      required,
      slug
    } = this.props;

    const name = `${objectPrefix}[${slug}]`;

    return {
      caption: question_text,
      className: 'col-12',
      id: slug,
      isFloatOutside: true,
      name,
      required
    }
  }

  isDisabled() {
    if (this.props.allow_from_url) {
      if (this.props.editable) {
        return null;
      } else {
        return "disabled";
      }
    }
  }

  textControl = () => {
    const { allow_from_url, freeTextValue, errors } = this.props;
    const value = allow_from_url ? freeTextValue : null;

    const formFieldAttributes = {
      ...this.getSharedFormFieldAttributes(),
      'data-question-type': 'free-text',
      disabled: this.isDisabled(),
      placeholder: '',
      type: allow_from_url ? 'hidden' : 'text',
      value,
      errorMessage: errors
    }
    return (
      <RegistrationQuestion formFieldAttributes={formFieldAttributes} />
    );
  };

  dropdownControl = () => {
    const { answers, onSelectChange, selected, slug } = this.props;

    return (
      <RegistrationQuestion formFieldAttributes={this.getSharedFormFieldAttributes()}>
        <SelectControl
          className="dropdown-no-padding"
          labelKey="answer_text"
          multi={false}
          name="response_set"
          onChange={(evt) => onSelectChange(evt, slug)}
          options={answers}
          placeholderText={i18n.t('Select')}
          value={selected}
          valueKey="id"
        />
      </RegistrationQuestion>
    );
  }
  radioControl = () => {
    const { answers, onSelectChange, selected, slug, errors } = this.props;
    const { className: defaultClass, ...attributes } = this.getSharedFormFieldAttributes();

    const formFieldAttributes = {
      ...attributes,
      errorMessage: errors,
      className: `${defaultClass} next-radio-control-group`
    };

    answers.forEach(answer => { answer.id = String(answer.id); });

    return (
      <RegistrationQuestion formFieldAttributes={formFieldAttributes}>
        <RadioGroupControl
          dataValue={selected}
          labelKey="answer_text"
          name="radio_cake"
          onChange={(value) => onSelectChange({ answer_text: value, id: slug })}
          valueList={answers}
          valueKey="id"
        />
      </RegistrationQuestion>
    );
  }

  renderQuestionType() {
    switch (this.props.question_type) {
    case 'radio':
      return this.radioControl();
    case 'drop-down':
      return this.dropdownControl();
    case 'text':
      return this.textControl();
    default:
      return null;
    }
  }

  render() {
    return (
      <FormGroup className={`additional-reg-form ${this.props.errors ? 'is-invalid' : null}`}>
        {this.renderQuestionType()}
      </FormGroup>
    );
  }
}
