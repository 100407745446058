import PropTypes from 'prop-types';
import React from 'react';
import PlaylistCard from '../../../components/PlaylistCard';
import DiagnosticCard from '../../../components/DiagnosticCard';
import { getCustomizedText } from 'lib/utility/helpers';

const OtherPlaylists = ({ currentPlaylistId = 0, program, program: { diagnostics = [], playlists = [], text_customization } }) => {
  const additionalPlaylistsText = getCustomizedText(text_customization, 'additional_playlists_text');
  if (!playlists.filter(pl => pl.id !== currentPlaylistId).length && !diagnostics.length) return null;

  return (
    <div className="container">
      <div className="row other-playlists-header">
        <div className="col-12">
          <h2 id="discover">{additionalPlaylistsText}</h2>
        </div>
      </div>
      <div className="row other-playlists clearfix">
        <div className="col-12 col-centered">
          {playlists.map(playlist => {
            return playlist.id === currentPlaylistId ? null : (
              <PlaylistCard
                key={playlist.id}
                playlist={playlist}
                program={program}
                learnerDashboard={true}
              />
            );
          })}
          {diagnostics.map(diagnostic =>
            <DiagnosticCard
              diagnostic={diagnostic}
              key={diagnostic.id}
              program={program}
              learnerDashboard={true}
            />
          )}
        </div>
      </div>
    </div>
  )
}

OtherPlaylists.propTypes = {
  currentPlaylistId: PropTypes.string,
  program: PropTypes.shape({
    diagnostics: PropTypes.array,
    playlists: PropTypes.array,
    text_customization: PropTypes.object
  })
};

export default OtherPlaylists;
