import PropTypes from 'prop-types';
import React from 'react';
import i18n from 'lib/utility/i18n';
import { IconCategoryValue } from 'reactifi/dist/es/components/icon-category-value';
import { ProgressSpinner } from 'reactifi/dist/es/components/progress-spinner';
import { pollUrl } from 'reactifi/dist/es/actions/downloadActions';
import { getLocaleVersion, isIncentiveActive } from '../../../common/functions';

const poll = {
  INTERVAL: 3000,
  TIMEOUT: 120000 // 2 minutes
};

export default class Header extends React.Component {
  static propTypes = {
    diagnostic: PropTypes.any,
    handleIncentiveCompleteGateways: PropTypes.func,
    //not inside the playlist because it might come from a diagnostic instead
    incentive: PropTypes.shape({
      archived: PropTypes.bool,
      background_image: PropTypes.string,
      background_image_completed: PropTypes.string,
      claim_message: PropTypes.string,
      hide_complete_cta: PropTypes.bool,
      hide_incomplete_cta: PropTypes.bool,
      id: PropTypes.string,
      incentive_activity: PropTypes.shape({
        status: PropTypes.string
      }),
      incentive_message: PropTypes.string,
      incentive_complete_text: PropTypes.string,
      incentive_complete_url: PropTypes.string,
      incentive_incomplete_text: PropTypes.string,
      incentive_incomplete_url: PropTypes.string
    }),
    program: PropTypes.shape({
      text_customization: PropTypes.shape({
        activities_text: PropTypes.string
      })
    }).isRequired,
    playlist: PropTypes.shape({
      allCompleted: PropTypes.bool,
      content: PropTypes.array,
      description: PropTypes.string,
      id: PropTypes.String,
      name: PropTypes.string
    }).isRequired,
    retakeSurvey: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      incentiveActivityStatus: null,
      polling: false,
      pollingDone: false,
      incentiveQuestionsCompleted: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.incentive) {
      const { incentive: { incentive_activity = {} } } = props;
      if (state.incentiveActivityStatus !== 'completed' && incentive_activity.status !== state.incentiveActivityStatus) {
        return {
          incentiveActivityStatus: incentive_activity.status
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  goToDiscover = () => {
    document.querySelector('#discover').scrollIntoView(true);
  }

  componentDidMount() {
    let width = document.documentElement.clientWidth
    if (width < 768) {
      this.setState({ isMobile: true });
    }
  }

  incentiveLinkClicked = (allCompleted, destinationUrl) => {
    return (event) => {
      if (allCompleted && !this.state.incentiveQuestionsCompleted) {
        event.preventDefault();
        this.props.handleIncentiveCompleteGateways(() => {
          this.state.incentiveQuestionsCompleted = true;
          window.open(destinationUrl, '_blank');
        });
      } else {
        window.open(destinationUrl, '_blank');
      }
    }
  }


  timesUp = () => {
    this.setState({ pollingDone: true, polling: false });
  }

  updateIncentiveActivityStatus = (data) => {
    this.setState({
      incentiveActivityStatus: data.data.attributes.status,
      polling: false,
      pollingDone: true
    });
  }

  fetchOrThrowError = async (url) => {
    let response = await fetch(url);
    let json = await response.json();
    if (response.ok) {
      return json;
    } else {
      this.setState({ pollingDone: true });
      throw Error(json.error);
    }
  }

  mayStopPolling = (data) => {
    return (data.data && data.data.attributes.status === 'completed' || this.state.pollingDone);
  }

  startPolling = async () => {
    // pollUrl(interval, max, getData, checkComplete, onFinish, onTimeout)
    const incentiveActivity = this.props.incentive?.incentive_activity;
    if (incentiveActivity && !this.state.polling && this.state.incentiveActivityStatus !== 'completed' && !this.state.pollingDone) {
      this.setState({ polling: true });
      const id = incentiveActivity.id;

      pollUrl({
        interval: poll.INTERVAL,
        max: poll.TIMEOUT,
        getData: async () => await this.fetchOrThrowError(`/api/data/incentive_activities/${id}`),
        checkComplete: this.mayStopPolling,
        onFinish: async data => this.updateIncentiveActivityStatus(data),
        onTimeout: this.timesUp
      });
    }
  }

  incentiveContent = () => {
    const { incentive } = this.props;
    if (!isIncentiveActive(incentive)) return false;
    const incentiveComplete = this.state.incentiveActivityStatus === 'completed';
    const completeValues = {
      background: incentive.background_image_completed,
      header: incentive.claim_message,
      text: incentive.incentive_complete_text,
      url: incentive.incentive_complete_url,
      hideCta: incentive.hide_complete_cta
    };
    const incompleteValues = {
      background: incentive.background_image,
      header: incentive.incentive_message,
      text: incentive.incentive_incomplete_text,
      url: incentive.incentive_incomplete_url,
      hideCta: incentive.hide_incomplete_cta
    }
    const values = incentiveComplete ? completeValues : incompleteValues;

    const incentiveDataAttributes = {
      'data-attribute': !incentiveComplete ? 'incentive_message' : 'claim_message',
      'data-object-type': "incentives",
      'data-object-id': incentive.id
    };

    if (!incentiveComplete && this.props.playlist.allCompleted) {
      this.startPolling();
    }

    return (
      <div className={`incentive-learner col-12 col-md-5 ${values.background ? 'incent-has-bg' : ''}`}>
        <div
          className="incentive"
          data-object-type="incentives"
          data-object-id={incentive.id}
          id="incentive-container"
        >
          <header>
            <div className="header-content">
              <IconCategoryValue
                {...incentiveDataAttributes}
                icon="star"
                tooltip={!incentiveComplete ? i18n.t('It may take a few moments to see if you qualified. If the delay persists for several hours, please contact support') : ''}
                tooltipPlacement="top"
                value={!incentiveComplete ? i18n.t('Incentive Available') : i18n.t('Incentive Achieved!')}
              />
              <p className="p3 incentive-details">
                {i18n.t(`${values.header}`)}
              </p>
            </div>
          </header>
          {values.hideCta ? null :
            <a
              aria-label={i18n.t('Learn More')}
              className="capitalize small incentive-text"
              data-action="open-cta-link"
              data-object-type="incentives"
              data-object-id={incentive.id}
              onClick={this.incentiveLinkClicked(incentiveComplete, values.url)}
            >
              {!incentiveComplete ? i18n.t(`${incentive.incentive_incomplete_text}`) : i18n.t(`${incentive.incentive_complete_text}`)}
            </a>
          }
          {this.state.polling &&
            <p className="incentive-loading">
              <div className="incentive-preloader">
                <ProgressSpinner classNames={"preloader pls-path"} />
                <p className="incentive-loading-message p3 fst-italic">{i18n.t('Checking your completion status')}</p>
              </div>
            </p>
          }
          {(this.state.pollingDone && this.state.incentiveActivityStatus !== 'completed') &&
            <p className="incentive-loading incentive-loading-error">{i18n.t('Could not retrieve status. Please check again later.')}</p>
          }
        </div>
        {values.background && (
          <img
            className="incent-background"
            data-attribute={!incentiveComplete ? 'background_image' : 'background_image_completed'}
            data-object-type="incentives"
            data-object-id={incentive.id}
            src={values.background}
          />
        )}
      </div>
    )
  }

  render() {
    let { diagnostic, playlist, retakeSurvey } = this.props;
    let mobile = this.state.isMobile;
    const description = mobile && playlist.allCompleted ?
      <span>{i18n.t('Congrats! Want to learn more? Then, ')}
        <a onClick={this.goToDiscover}>{i18n.t('discover additional playlists.')}</a>
      </span> :
      getLocaleVersion(playlist, 'description'); 


    return (
      <div className="row playlist-header clearfix" >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="row">
                <div className="col-12">
                  <h1
                    className="title"
                    data-attribute="name"
                    data-object-type="playlists"
                    data-object-id={playlist.id}
                  >
                    {getLocaleVersion(playlist, 'name')}
                  </h1>
                </div>
              </div>
              {description &&
                <div className="row">
                  <div className="col-12">
                    <p
                      className="description m-b-20 subtitle regular"
                      data-attribute="description"
                      data-object-type="playlists"
                      data-object-id={playlist.id}
                    >
                      {description}
                    </p>
                  </div>
                </div>
              }
              {diagnostic &&
                <div className="update-answers">
                  <a onClick={retakeSurvey}>{i18n.t('Take Survey Again')}</a>
                </div>
              }
            </div>
            {this.incentiveContent()}
          </div>
        </div>
      </div>
    )
  }
}
