import { JsonApiDecorator } from 'reactifi/dist/es/json-api/JsonApiDecorator';

export default function mapStateToProps(state, ownProps) {
  const props = { ...ownProps };
  const apiStore = new JsonApiDecorator(state.api);
  const { programs, gateways } = apiStore;

  props.program = programs ? programs.first('account') : null;
  props.gateways = gateways ? gateways.all() : [];

  return props;
}
