import React from 'react';
import createStore from './store/trueLearnerDashboardStore';
import { RootProvider } from 'reactifi/dist/es/react-on-rails/RootProvider';
import TrueLearnerDashboardContainer from './containers/TrueLearnerDashboardContainer';

export default (props, _railsContext) => {
  const store = createStore(props || {});
  return (
    <RootProvider store={store}>
      <TrueLearnerDashboardContainer
        {...props}
        contentServiceUrl={_railsContext.content_service_url}
        jwt={_railsContext.jwt}
      />
    </RootProvider>
  );
};
