import PropTypes from 'prop-types';
import React from 'react';
import SharedCard from './SharedCard';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default class PlaylistCard extends React.Component {
  static propTypes = {
    program: PropTypes.shape({
      remove_content_images: PropTypes.bool,
      slug: PropTypes.string.isRequired
    }),
    playlist: PropTypes.shape({
      image_url: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      incentive: PropTypes.shape({
        claim_message: PropTypes.string,
        incentive_activity: PropTypes.shape({
          status: PropTypes.string
        }),
        incentive_message: PropTypes.string
      }),
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      tagline: PropTypes.string
    }).isRequired,
    genericIncentive: PropTypes.object,
    learnerDashboard: PropTypes.bool
  }

  render() {
    const { genericIncentive, playlist, program, learnerDashboard } = this.props;
    const playlistUrl = sanitizeUrl(`/student/dashboard/${program.slug}/${playlist.slug}${window.location.search}`);
    const iconUrl = playlist.image_url ? playlist.image_url : "/assets/investments-logo.png";

    return (
      <SharedCard
        {...playlist}
        cardType="playlist"
        destinationUrl={playlistUrl}
        genericIncentive={genericIncentive}
        iconUrl={iconUrl}
        program={program}
        learnerDashboard={learnerDashboard}
      />
    )
  }
}
