import PropTypes from 'prop-types';
import React from 'react';
import HelpModal from '../../bundles/user/components/help_modal';
import Modal from 'react-bootstrap/Modal';
import i18n from "lib/utility/i18n";
import { FAIcon } from 'reactifi/dist/es/components/fa-icon';
import { sanitizeUrl } from '@braintree/sanitize-url';

var LINK_VISISTED = -1;
export default class GenericModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    canClose: PropTypes.bool,
    children: PropTypes.node,
    modalClass: PropTypes.string,
    id: PropTypes.string,
    show: PropTypes.bool,
    logo: PropTypes.object,
    bsSize: PropTypes.string,
    showMenu: PropTypes.bool,
    showHeaderText: PropTypes.bool,
    locales: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
      current: PropTypes.bool
    }))
  }

  static defaultProps = {
    modalClass: '',
    showMenu: false
  }

  constructor(props) {
    super(props);
    this.state = {};
    this.menu = this.menu.bind(this);
    this.handleSubMenuClick = this.handleSubMenuClick.bind(this);
    this.handleSubMenuKeyDown = this.handleSubMenuKeyDown.bind(this);
    this.handleHomeKeyUp = this.handleHomeKeyUp.bind(this);
    this.languageRedirect = this.languageRedirect.bind(this);
  }

  handleSubMenuClick(e) {
    $('#navbar li.nav-item.dropdown').addClass('open');
  }

  handleSubMenuKeyDown(e) {
    var focusableItemListFromSubmenu = Array.from(document.querySelectorAll('.language-menu:first-of-type [tabindex="-1"]'));
    if (e.keyCode === 40) {
      if ($('#navbar li.dropdown.language-item.open')) {
        $('#navbar li.nav-item.dropdown').addClass('open');
        if (LINK_VISISTED < focusableItemListFromSubmenu.length - 1) {
          $(focusableItemListFromSubmenu[++LINK_VISISTED]).focus();
        } else {
          $('#navbar li.dropdown.language-item.open').removeClass('open');
          $('#navbar .close-link a').focus();
        }
      }
    }
    else if (e.keyCode === 38) {
      $('#navbar li.nav-item.dropdown').addClass('open');
      $('#navbar li.dropdown.language-item').addClass('open');
      if (LINK_VISISTED >= 0 && focusableItemListFromSubmenu.length) {
        $(focusableItemListFromSubmenu[LINK_VISISTED--]).focus();
      } else {
        $('#navbar li.dropdown.language-item').removeClass('open');
        $('#navbar .accessibility-link a').focus();
      }
    }
  }

  handleHomeKeyUp(e) {
    if (e.keyCode === 38) {
      $('#navbar .language-item a').focus();
    }
  }

  languageRedirect(locale) {
    window.location.href = sanitizeUrl(locale.current ? window.location.href + "#" : locale.url);
  }

  menu() {
    return (
      <ul id="navbar" className="student-content-menu nav pull-right" aria-expanded="false" aria-label={i18n.t('Main menu')}>
        <li className="nav-item dropdown" >
          <a id="menu-dropdown" type="button" className="dropdown-toggle" data-toggle="dropdown" tabIndex="0" aria-expanded="false" aria-haspopup="true" title={i18n.t('Main menu')}>
            <FAIcon icon="gear" additionalClasses="menu-icon" />
          </a>
          <ul className="dropdown-menu dropdown-menu-right" role="menu" aria-label={i18n.t("Menu Dropdown")}>
            <li className="dropdown-item" role="presentation">
              <HelpModal />
            </li>
            <li className="dropdown-item" role="presentation">
              <a href="https://everfi.com/terms/" target="_blank" rel="noopener noreferrer"><FAIcon icon="file" additionalClasses="m-l-sm" />{i18n.t('Terms of Service')}</a>
            </li>
            <li className="dropdown-item accessibility-link" role="presentation">
              <a href="https://everfi.com/accessibility/" target="_blank" rel="noopener noreferrer"><FAIcon icon="wheelchair" additionalClasses="m-l-sm" />{i18n.t('Accessibility')}</a>
            </li>
            {this.props.locales && this.props.locales.length > 1 ?
              <li className="dropdown language-item" onKeyDown={this.handleSubMenuKeyDown}>
                <a onClick={this.handleSubMenuClick} id="language-dropdown" className="dropdown-toggle" role="button" data-toggle="dropdown" href="#">
                  <span><FAIcon icon="language" />{i18n.t('Language')}</span>
                  <span><span className="caret" /></span>
                </a>
                <ul className="dropdown-menu language-menu" role="menu" aria-label={i18n.t("Language Dropdown")}>
                  {this.props.locales.map((locale) =>
                    <li className="dropdown-item">
                      <a role="menuitem" tabIndex="-1" onClick={(e) => this.languageRedirect(locale)} href="#">{locale.current ? <FAIcon icon="check" /> : null}{locale.name}</a>
                    </li>)
                  }
                </ul>
              </li> : null}
            <li className="dropdown-item close-link" onKeyDown={this.handleHomeKeyUp} role="presentation">
              <a onClick={this.props.onClose} href="#"><FAIcon icon="house-chimney" additionalClasses="m-l-sm" />{i18n.t('Home')}</a>
            </li>
          </ul>
        </li>
      </ul>
    );
  }

  render() {
    let { onClose, modalClass, canClose, id, logo, bsSize, showMenu, showHeaderText } = this.props;
    let show = this.props.show !== undefined ? this.props.show : true;

    return (
      <Modal animation={false} id={id} className={modalClass} show={show} onHide={onClose} keyboard={canClose} backdrop={canClose ? true : 'static'} bsSize={bsSize} >
        {logo || canClose || showHeaderText ? <Modal.Header>
          {logo && <img src={logo.url} alt={logo.alt} className="img-fluid" />}
          {showMenu ? this.menu() : null}
          {canClose && !showMenu && <a onClick={onClose} className="close-link" href="#" aria-label={i18n.t("Close")}><FAIcon icon="xmark" /></a>}
          {showHeaderText && <h2 className="modal-title">{i18n.t('Please answer the following questions')}</h2>}
        </Modal.Header> : null}
        <Modal.Body>
          {this.props.children}
        </Modal.Body>
      </Modal>
    );
  }
}
