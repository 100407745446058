import PropTypes from 'prop-types';
import React from 'react';
import LoginBox from '../../../components/login_box';
import ResponseSetForm from '../../../components/response_set_form';
import GenericModal from 'lib/components/GenericModal';
import PlatformModal from 'lib/utility/platform_modal';
import $ from 'jquery';
import { storage as Storage } from 'reactifi/dist/es/utils/Storage';

export default class GatewayModal extends React.Component {

  static propTypes = {
    answers: PropTypes.object,
    authUrls: PropTypes.array,
    foundryLinkUrl: PropTypes.string,
    gatewayToPresent: PropTypes.shape({
      closeable: PropTypes.bool,
      next: PropTypes.any,
      questions_show_during_registration: PropTypes.array,
      questions_show_at_first_activity: PropTypes.array,
      questions_show_on_incentive_completion: PropTypes.array,
      type: PropTypes.string
    }),
    loginText: PropTypes.string,
    onModalClose: PropTypes.func,
    programAuthCaps: PropTypes.array,
    programId: PropTypes.string
  }

  componentWillUnmount() {
    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    if (isIE11) {
      // when a demographic question comes right after a registration, React loses track of the backdrop in IE
      $('.modal-backdrop', $('.login-reg-modal').parent()).remove();
    }
    $('.login-reg-modal').hide();
    PlatformModal.close();
  }

  isSaveOnly = () => {
    const { gatewayToPresent: {
      questions_show_during_registration,
      questions_show_at_first_activity,
      questions_show_on_incentive_completion,
      type
    } } = this.props;

    if (['additional_fields', 'demographic', 'incentive'].includes(type)) {
      let questionArray;

      if (type === 'additional_fields') {
        questionArray = questions_show_during_registration;
      } else if (type === 'demographic') {
        questionArray = questions_show_at_first_activity;
      } else if (type === 'incentive') {
        questionArray = questions_show_on_incentive_completion;
      }

      let visibleQuestions = questionArray.filter((question) => !question.allow_from_url);
      return visibleQuestions.length === 0;
    }

    return false;
  }

  gatewayComponent = () => {
    const {
      answers,
      gatewayToPresent: {
        closeable,
        questions_show_during_registration,
        questions_show_at_first_activity,
        questions_show_on_incentive_completion,
        type
      },
      programId,
      programAuthCaps
    } = this.props;
    switch (type) {
    case 'login':
      return (<LoginBox
        loginText={this.props.loginText}
        programId={programId}
        foundryLinkUrl={this.props.foundryLinkUrl}
        authCaps={programAuthCaps}
        authUrls={this.props.authUrls}
      />);
    case 'additional_fields':
      if (questions_show_during_registration.length > 0) {
        return (<ResponseSetForm
          canClose={closeable}
          questions={questions_show_during_registration}
          onSuccess={() => { this.dismissModal(true) }}
          answers={answers}
          location="registration"
          display_location="during_registration"
          autoSubmit={this.isSaveOnly()}
          programId={programId}
        />)
      }
      break;
    case 'demographic':
      if (questions_show_at_first_activity.length > 0) {
        return (<ResponseSetForm
          canClose={closeable}
          questions={questions_show_at_first_activity}
          onSuccess={() => { this.dismissModal(true) }}
          answers={answers}
          location="demographic"
          display_location="at_first_activity"
          autoSubmit={this.isSaveOnly()}
          programId={programId}
        />)
      }
      break;
    case 'incentive':
      if (questions_show_on_incentive_completion.length > 0) {
        return (<ResponseSetForm
          canClose={closeable}
          questions={questions_show_on_incentive_completion}
          onSuccess={() => { this.dismissModal(true); }}
          answers={answers}
          display_location="on_incentive_completion"
          autoSubmit={this.isSaveOnly()}
          programId={programId}
        />)
      }
      break;
    default:
      return null;
    }
  }

  dismissModal = (forceClose = false) => {
    const { gatewayToPresent, onModalClose } = this.props;
    if (gatewayToPresent.closeable || forceClose) {
      this.storeGatewayCount();
      if (onModalClose) {
        onModalClose();
      }
    }
  }

  storeGatewayCount = () => {
    const { gatewayToPresent: { type } } = this.props;

    if (!type) return;

    // get current prompt count for this gateway type
    const storageKey = `gatewayPromptCount-${type}`;
    const currentCount = Number(Storage.getItem(storageKey));

    // add one to that count and store it
    Storage.setItem(storageKey, currentCount + 1);
  }

  /*
    React was quietly suppressing errors before upgrading to 16. These will
    continue to suppress as before and avoid app crashes while also allowing
    us to see with better detail what that error actually is.
  */
  static getDerivedStateFromError(error) { }
  componentDidCatch(error, errorInfo) { console.error(error, errorInfo) }

  render() {
    const { gatewayToPresent } = this.props;
    if (this.isSaveOnly()) {
      return this.gatewayComponent(gatewayToPresent.type);
    } else {
      if (gatewayToPresent) {
        PlatformModal.open();
      }

      return (
        <GenericModal
          show={true}
          onClose={this.dismissModal}
          modalClass={`material-modal ${gatewayToPresent.type === 'login' ? 'login-reg-modal' : 'demo-modal'} ${gatewayToPresent.type}`}
          showHeaderText={gatewayToPresent.type !== 'login'}
          canClose={gatewayToPresent.closeable}
        >
          {this.gatewayComponent()}
        </GenericModal>
      );
    }
  }
}
