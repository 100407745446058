import React, { useState } from 'react';
import PropTypes from 'prop-types'
import ItemIcon from 'lib/components/ItemIcon';
import i18n from 'lib/utility/i18n';
import LanguageUnavailableModal from './LanguageUnavailableModal';
import { FAIcon } from 'reactifi/dist/es/components/fa-icon';
import { Button } from 'reactifi';
import { normalizeLocaleString } from '../../../common/functions';

const Item = ({ content, handleContentNav, showIcons, processStatus }) => {
  const { description, id, image_url, is_progressable, name } = content;
  const [showLanguageActive, setShowLanguageActive] = useState(false);

  const handleContentClick = event => {
    const ignoreTargets = ['m-toggle', 'toggleaction', 'actionplan', 'm-desc', 'fa-tasks'];
    if (!ignoreTargets.includes(event.target?.className)) {
      navigateToContent();
    }
  }

  const keyboardEnter = event => {
    if (event.which === 13) {
      navigateToContent();
    }
  }

  const isMissingSelectedLocale = content => {
    const { supported_locales = [] } = content;
    const supportedLocales = supported_locales.map(normalizeLocaleString);
    const locale = (new URLSearchParams(window.location.search)).get('locale');

    return locale && !supportedLocales.includes(normalizeLocaleString(locale));
  }

  const navigateToContent = () => {
    if (isDisabled()) return;

    if (isMissingSelectedLocale(content)) {
      setShowLanguageActive(true);
    } else {
      handleContentNav(content);
    }
  }

  const isDisabled = () => {
    const { user_content: { progress } = {} } = content;
    let disabled = false;
    if (progress) {
      if (progress.status === 'completed') {
        disabled = ['assessment', 'poll', 'survey'].includes(progress.type);
      }
    }
    return disabled;
  }

  const percentComplete = () => {
    const { user_content: { progress } = {} } = content;
    if (!progress) return 0;

    switch (progress.status) {
    case 'not_started':
      return 0;
    case 'completed':
      return 100;
    case 'in_progress':
      return (progress.units_completed * 100) / progress.total_units;
    default:
      return 0;
    }
  }

  const hideConfirmation = () => setShowLanguageActive(false);

  const showEnglishCourse = () => {
    let params = new URLSearchParams(window.location.search);
    params.set('locale', 'en-US');
    history.pushState({}, '', window.location.origin + window.location.pathname + '?' + params.toString());
    window.sessionStorage.setItem("dashboardChangedLocale", true);
    setShowLanguageActive(false);
    handleContentNav(content);
  }

  const actionState = () => {
    const { user_content: { progress } = {} } = content;
    if (!progress) return i18n.t('start');

    switch (progress.status) {
    case 'not_started':
      return i18n.t('start');
    case 'in_progress':
      return progress.data?.type === 'third_party_js_css' ? i18n.t('review') : i18n.t('continue');
    case 'completed':
      return i18n.t('review');
    default:
      return i18n.t('continue');
    }
  }

  const getDuration = () => {
    const { user_content: { progress } = {} } = content;
    const contentDuration = content.duration || 10;
    const started = progress?.status === 'in_progress';

    let duration, durationTitle;
    if (started) {
      const durationRemaining = Math.round(contentDuration * (100 - percentComplete()) / 100);
      durationTitle = i18n.t('moduleMinutesLeftTitle', { postProcess: 'sprintf', sprintf: [durationRemaining] });
      duration = <span dangerouslySetInnerHTML={{
        __html: i18n.t('moduleMinutesLeftHtml',
          { postProcess: 'sprintf', sprintf: [durationRemaining] })
      }} />
    } else {
      durationTitle = i18n.t('%d Minutes', { postProcess: 'sprintf', sprintf: [contentDuration] });
      duration = i18n.t('moduleTotalMinutes', { postProcess: 'sprintf', sprintf: [contentDuration] });
    }
    return (
      <>
        <div aria-hidden="true" className={duration.type ? 'length-xl' : 'length'}>
          {duration}
        </div>
        <span className="screen-reader-content">{durationTitle}</span>
      </>
    );
  }

  return (
    <div className="container-fluid item" data-attribute="content-item" data-object-type="content" data-object-id={id}>
      <a className="flexed-link" data-action="start-content" onClick={handleContentClick} onKeyDown={keyboardEnter} role="button">
        {showIcons &&
          <div className="d-none d-sm-block col-2 icon-grid">
            <ItemIcon id={`content-${(processStatus || '').replace(" ", '')}-${id}`} icon_url={image_url ? image_url : "/assets/investments-logo.png"} />
          </div>
        }
        <div className={`${showIcons ? "col-sm-12 col-md-6" : "col-12 col-sm-8"} content-col`}>
          <h3 className="title primary branded-header" id={`course-title-${id}`}>{name}</h3>
          <p className="description p1">{description}</p>
        </div>
        <div className="col-12 col-md-4 item-align">
          <div className="col-7 col-sm-6 flexplay">
            <div className="meta">
              {is_progressable && getDuration()}
            </div>
          </div>
          <div className="col-5 col-sm-6 flexplay-right">
            <div className="play">
              <Button
                aria-label={`${actionState()} course`}
                aria-labelledby={`course-title-${id}`}
                className={`capitalize ${isDisabled() ? ' disabled' : ''} ui-default`}
                data-action="start-content"
                data-attribute="content-start-button"
                data-object-type="content"
                data-object-id={id}
                style="primary"
                branded={true}
              >
                {actionState()} <FAIcon icon="angle-right" additionalClasses="right" />
              </Button>
            </div>
          </div>
        </div>
      </a>
      <LanguageUnavailableModal
        show={showLanguageActive}
        onCancel={hideConfirmation}
        onConfirm={showEnglishCourse}
      />
    </div>
  );
}

Item.propTypes = {
  content: PropTypes.shape({
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    image_url: PropTypes.string,
    is_progressable: PropTypes.bool.isRequired,
    name: PropTypes.string,
    prompt_language_warning: PropTypes.bool,
    supported_locales: PropTypes.array,
    user_content: PropTypes.shape({
      progress: PropTypes.shape({
        status: PropTypes.string,
        type: PropTypes.string,
        total_units: PropTypes.number,
        units_completed: PropTypes.number
      })
    })
  }).isRequired,
  handleContentNav: PropTypes.func.isRequired,
  showIcons: PropTypes.bool,
  processStatus: PropTypes.string
};

export default Item;
